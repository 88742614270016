import React, { createContext, useContext, useEffect, useState } from 'react';
import { useApi } from 'api/ApiProvider';
import Api from 'axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { AuthContext, AuthStatus } from 'components/authentication/context/authContext';
import intl from 'react-intl-universal';
import { UserEntityDetails } from 'axiosApi/models';
import Spinner from 'components/Spinner';
let failures = 0;

export const UserEntityContext = createContext<UserEntityDetails>(null);

export const UserEntityProvider = ({ children }) => {
  const [userEntityDetails, setUserEntityDetails] = useState(() => {
    const savedEntity = sessionStorage.getItem('UserEntity');
    return savedEntity ? JSON.parse(savedEntity) : null;
  });
  
  const api: Api = useApi();
  const authContext = useContext(AuthContext);

  const toast = useToastMessageQueue();

  const [retry, setRetry] = useState(0);
  const RETRY_LIMIT = 3;

  const fetchUserEntityDetails = async () => {
    try {
      const response = await api.loginApi.apiVversionLoginEntitiesGet("1", sessionStorage.getItem('username'));
      if (response.data.data && response.data.data.length > 0 && !!response.data.data[0]) {
        console.log("Grabbed data");
        const details = response.data.data[0];
        setUserEntityDetails(details);
        sessionStorage.setItem('UserEntity', JSON.stringify(details));
        setRetry(0);
      } else {
        sessionStorage.removeItem('UserEntity');
        toast.error({ header: 'Error', body: intl.get('employeeProvider.toast.error') });
        authContext.signOut();
        window.location.href = "/";
      }
    } catch (error) {
      setRetry((prev) => prev + 1);
    }
  }

  useEffect(() => {
    if (authContext && authContext.authStatus === AuthStatus.SignedIn && !userEntityDetails && retry < RETRY_LIMIT) {
      fetchUserEntityDetails();
    }
  }, [authContext, userEntityDetails, retry]);

  return <UserEntityContext.Provider value={userEntityDetails} >
    {!userEntityDetails && authContext.authStatus === AuthStatus.SignedIn?<Spinner />:children}
  </UserEntityContext.Provider >
}

export function getUserEntity() {
  return useContext(UserEntityContext);
}

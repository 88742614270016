import React, {useContext} from "react";
import { useLocation } from 'react-router';
import {MainNavigation} from "./MainNavigation";
import {Footer} from "./Footer";
import '../scss/App.scss';
import  { AuthContext, AuthStatus } from './authentication/context/authContext'
import SignInButton from "./SignInButton";
import Spinner from './Spinner';
/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const  PageLayout = (props) => {
    const { authStatus } = useContext(AuthContext)
    const isAuthenticated = authStatus === AuthStatus.SignedIn;
    const location = useLocation();
    const { hash, pathname, search } = location;
    return (
        <>
            <header className="sticky-top"><MainNavigation isAuthenticated={isAuthenticated}/></header>
            {props.children}
            <Footer/>
        </>
    );
};
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import {PermissionsGate } from '../../components/PermissionsGate';
import { SCOPES } from "../../common/permissions"; 
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import { Form, Row, Col, Label, Input } from 'reactstrap';
import { Employee, Role, Client, Provider, ActivateSecurityRoleCommand, UpdateUserCommand } from 'axiosApi/models';
import { UpdateUserSecurityRolesCommand } from 'axiosApi/models/update-user-security-roles-command';
import { HiOutlinePlusCircle, HiArrowLeft } from 'react-icons/hi';
import UserAsEmployeeEdit from './UserAsEmployeeEdit';
import UserAsClientEdit from './UserAsClientEdit';
import UserAsProviderEdit from './UserAsProviderEdit';
import "../../scss/User/UserDetail.scss";
import { v4 as uuidv4 } from 'uuid';
import FormUserAsEmployee from './FormUserAsEmployee';
import Spinner from '../Spinner';
import { confirmAlert } from 'react-confirm-alert';
import FormUserAsClient from '../User/FormUserAsClient';
import FormUserAsProvider from '../User/FormUserAsProvider';
import { handleAPIError } from 'common/errorHandler';
import ErrorSummary from 'components/common/ErrorSummary';

const UserDetail = () => {

    const DefaultUserInput = {
        active: null,
        email: '',
        idStatus: null,
        userStatus: '',
        userDetails: []
    };

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [input, setInput] = useState(DefaultUserInput);

    const [employees, setEmployees] = useState<Employee[]>([]);

    const [roles, setRoles] = useState<Role[]>([]);

    const [clients, setClients] = useState<Client[]>([]);

    const [providers, setProviders] = useState<Provider[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const [isFormUserAsEmployeeOpen, setIsFormUserAsEmployeeOpen] = useState(false);

    const [editedUserAsEmployee, setEditedUserAsEmployee] = useState({
        id: 0,
        entityType: 1,
        entityId: 0,
        securityRoles: [],
        idFe: null
    });

    const [isFormUserAsClientOpen, setIsFormUserAsClientOpen] = useState(false);

    const [editedUserAsClient, setEditedUserAsClient] = useState({
        id: 0,
        entityType: 2,
        entityId: 0,
        securityRoles: [],
        idFe: null
    });

    const [isFormUserAsProviderOpen, setIsFormUserAsProviderOpen] = useState(false);

    const [editedUserAsProvider, setEditedUserAsProvider] = useState({
        id: 0,
        entityType: 3,
        entityId: 0,
        securityRoles: [],
        idFe: null
    });

    const [edit, setEdit] = useState(false);
    const [errors, setErrors] = useState(null);

    const navigate = useNavigate();

    const generateUniqueId = () => uuidv4();

    const { id } = useParams();
    const idNumber = parseInt(id);

    const fetchEmployees = async () => {
        setLoading(true);
        const response = await api.employeeApi.apiVversionEmployeeAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setEmployees(response.data?.data?.queryResult);
            };
            console.log("Success");
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
                setErrors({ ...errors }); 
                setLoading(false);
        });
    };

    const fetchRoles = async () => {
        setLoading(true);
        const response = await api.securityRoleApi.apiVversionSecurityRoleAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setRoles(response.data?.data?.queryResult);
            };
            console.log("Success");
            setLoading(false);
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('employeeProject.toast.error.fetchRoles'), body: error.message });
            setLoading(false);
        });
    };

    const fetchClients = async () => {
        setLoading(true);
        const response = await api.clientApi.apiVversionClientAllGet("1", 1, 100, {}).then((response) => {
            if (response?.data?.data) {
                setClients(response?.data?.data?.queryResult);
            };
            setLoading(false);
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('projectDetail.toast.error.fetchClients'), body: error.message });
            setLoading(false);
        });
    };

    const fetchProviders = async () => {
        setLoading(true);
        const response = await api.providerApi.apiVversionProviderAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setProviders(response.data?.data?.queryResult);
            };
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors }); 
            setLoading(false);
        });
    };

    const fetchUserDetail = async (idNumber: number) => {
        setLoading(true);
        const response = await api.userApi.apiVversionUserIdGet(idNumber, "1", {}).then((response) => {
            if (response.data.data) {
                const userDetailsIdFe = response.data?.data?.userDetails?.map(user => ({
                    ...user,
                    idFe: generateUniqueId()
                }));
                setInput({
                    ...input,
                    active: response.data?.data?.active,
                    email: response.data?.data?.emailAddress,
                    userDetails: userDetailsIdFe
                })
                setLoading(false);
                console.log("Success");
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors }); 
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchEmployees();
        fetchRoles();
        fetchClients();
        fetchProviders();
        fetchUserDetail(idNumber);
    }, [idNumber]);

    const handleEmployeeChange = (selectedOption, id) => {
        setEditedUserAsEmployee({
            ...editedUserAsEmployee,
            entityId: selectedOption.id,
            idFe: id
        });
        setEdit(false);
    };

    const handleClientChange = (selectedOption, id) => {
        setEditedUserAsClient({
            ...editedUserAsClient,
            entityId: selectedOption.id,
            idFe: id
        });
        setEdit(false);
    };

    const handleProviderChange = (selectedOption, id) => {
        setEditedUserAsProvider({
            ...editedUserAsProvider,
            entityId: selectedOption.id,
            idFe: id
        });
        setEdit(false);
    };

    const handleRolesUserAsEmployeeChange = (event, id) => {
        const selectedRolesIds = event.map(role => role.id);
        setEditedUserAsEmployee({
            ...editedUserAsEmployee,
            securityRoles: selectedRolesIds
        });
    };

    const handleRolesUserAsClientChange = (event, id) => {
        const selectedRolesIds = event.map(role => role.id);
        setEditedUserAsClient({
            ...editedUserAsClient,
            securityRoles: selectedRolesIds
        });
    };

    const handleRolesUserAsProviderChange = (event, id) => {
        const selectedRolesIds = event.map(role => role.id);
        setEditedUserAsProvider({
            ...editedUserAsProvider,
            securityRoles: selectedRolesIds
        });
    };

    const handleSave = async (idFe) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.userDetail.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { 
                        setLoading(false);
                    }
                },
                {
                    label: intl.get('save.modal.save.button'),
                    onClick: async () => {
                        setLoading(true);
                        const userDetailEdited = input.userDetails.map(user => {
                            if (user.idFe === editedUserAsEmployee.idFe) {
                                return editedUserAsEmployee;
                            } else {
                                return user;
                            };
                        });
                        setInput({
                            ...input,
                            userDetails: userDetailEdited
                        });
                        if (editedUserAsEmployee.id) {
                            const userUpdated = input.userDetails.find(user => user.idFe === idFe);
                            const userDetailEditedSecurityRoles = userDetailEdited.find(user => user.idFe === idFe);
                            let cmd: UpdateUserSecurityRolesCommand = {
                                id: userUpdated.id ,
                                idUser: idNumber,
                                idEntity: userUpdated.entityId,
                                entityType: userUpdated.entityType,
                                securityRoles: userDetailEditedSecurityRoles.securityRoles
                            };
                            const response = await api.userApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave')});
                                };
                                setIsFormUserAsEmployeeOpen(!isFormUserAsEmployeeOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        } else {
                            let cmd: UpdateUserSecurityRolesCommand = {
                                id: null ,
                                idUser: idNumber,
                                idEntity: editedUserAsEmployee.entityId,
                                entityType: editedUserAsEmployee.entityType,
                                securityRoles: editedUserAsEmployee.securityRoles
                            };
                            const response = await api.userApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave.add')});
                                };
                                setIsFormUserAsEmployeeOpen(!isFormUserAsEmployeeOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        }
                    },
                }
            ]
        });
    };

    const handleSaveClient = async (idFe) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.userDetail.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { 
                        setLoading(false);
                    }
                },
                {
                    label: intl.get('save.modal.save.button'),
                    onClick: async () => {
                        setLoading(true);
                        const userDetailEdited = input.userDetails.map(user => {
                            if (user.idFe === editedUserAsClient.idFe) {
                                return editedUserAsClient;
                            } else {
                                return user;
                            };
                        });
                        setInput({
                            ...input,
                            userDetails: userDetailEdited
                        });
                        if (editedUserAsClient.id) {
                            const userUpdated = input.userDetails.find(user => user.idFe === idFe);
                            const userDetailEditedSecurityRoles = userDetailEdited.find(user => user.idFe === idFe);
                            let cmd: UpdateUserSecurityRolesCommand = {
                                id: userUpdated.id ,
                                idUser: idNumber,
                                idEntity: userUpdated.entityId,
                                entityType: userUpdated.entityType,
                                securityRoles: userDetailEditedSecurityRoles.securityRoles
                            };
                            const response = await api.userApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave')});
                                };
                                setIsFormUserAsClientOpen(!isFormUserAsClientOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        } else {
                            let cmd: UpdateUserSecurityRolesCommand = {
                                id: null ,
                                idUser: idNumber,
                                idEntity: editedUserAsClient.entityId,
                                entityType: editedUserAsClient.entityType,
                                securityRoles: editedUserAsClient.securityRoles
                            };
                            const response = await api.userApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave.add')});
                                };
                                setIsFormUserAsClientOpen(!isFormUserAsClientOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        }
                    },
                }
            ]
        });
    };

    const handleSaveProvider = async (idFe) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.userDetail.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { 
                        setLoading(false);
                    }
                },
                {
                    label: intl.get('save.modal.save.button'),
                    onClick: async () => {
                        setLoading(true);
                        const userDetailEdited = input.userDetails.map(user => {
                            if (user.idFe === editedUserAsProvider.idFe) {
                                return editedUserAsProvider;
                            } else {
                                return user;
                            };
                        });
                        setInput({
                            ...input,
                            userDetails: userDetailEdited
                        });
                        if (editedUserAsProvider.id) {
                            const userUpdated = input.userDetails.find(user => user.idFe === idFe);
                            const userDetailEditedSecurityRoles = userDetailEdited.find(user => user.idFe === idFe);
                            let cmd: UpdateUserSecurityRolesCommand = {
                                id: userUpdated.id ,
                                idUser: idNumber,
                                idEntity: userUpdated.entityId,
                                entityType: userUpdated.entityType,
                                securityRoles: userDetailEditedSecurityRoles.securityRoles
                            };
                            const response = await api.userApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave')});
                                };
                                setIsFormUserAsProviderOpen(!isFormUserAsProviderOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        } else {
                            let cmd: UpdateUserSecurityRolesCommand = {
                                id: null ,
                                idUser: idNumber,
                                idEntity: editedUserAsProvider.entityId,
                                entityType: editedUserAsProvider.entityType,
                                securityRoles: editedUserAsProvider.securityRoles
                            };
                            const response = await api.userApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave.add')});
                                };
                                setIsFormUserAsProviderOpen(!isFormUserAsProviderOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        }
                    },
                }
            ]
        });
    };

    const handleDelete = (idFe) => {
        const userDetailDelete = input.userDetails.find(user => user.idFe === idFe);
        confirmAlert({
            title: intl.get('delete.modal.title'),            
            message: intl.get('delete.modal.userDetail.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => {
                        setLoading(false);
                    }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: async () => {
                        setLoading(true);
                        const response = await api.userApi.apiVversionUserUserEntityIdUserEntityDelete(userDetailDelete.id, "1", {}).then((response) => {
                            if (response.data.data) {
                                const NewUserDetails = input.userDetails.filter(user => user.id !== userDetailDelete.id);
                                setInput({
                                    ...input,
                                    userDetails: NewUserDetails
                                });
                                toast.success({ body: intl.get('userRow.toast.success.handleDeleteUser')});
                            };
                            setLoading(false);
                        }).catch((error) => {
                            handleAPIError(error, toast, errors);    
                setErrors({ ...errors }); 
                setLoading(false);
                        });
                    },
                }
            ]
        });
    };    

    const handleStatus = async () => {
        setLoading(true);
        let cmd: UpdateUserCommand = {
            id: idNumber,
            active: !input.active,
            emailAddress: input.email,
            userDetails: input.userDetails,
        };
        const response = await api.userApi.apiVversionUserPut("1", cmd, {}).then((response) => {
            toast.success({ body: intl.get('roleRow.toast.success.handleStatus')});
            setInput({
                ...input,
                active: response?.data.data.active,
            })
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors }); 
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleBackClick = (e) => {
        navigate('/users');
    };

    const handleFormUserAsEmployeeOpen = () => {
        setIsFormUserAsEmployeeOpen(!isFormUserAsEmployeeOpen);
    };

    const handleFormUserAsClientOpen = () => {
        setIsFormUserAsClientOpen(!isFormUserAsClientOpen);
    };

    const handleFormUserAsProviderOpen = () => {
        setIsFormUserAsProviderOpen(!isFormUserAsProviderOpen);
    };

    const handleEditUserAsEmployee = (idFe) => {
        setEditedUserAsEmployee({
            id: 0,
            entityType: 1,
            entityId: 0,
            securityRoles: [],
            idFe: null
        });
        handleFormUserAsEmployeeOpen();
        if (idFe) {
            const editedEntity = input.userDetails.find(entity => entity.idFe === idFe);
            setEditedUserAsEmployee(editedEntity);
            setEdit(true);
        };
    };

    const handleEditUserAsClient = (idFe) => {
        setEditedUserAsClient({
            id: 0,
            entityType: 2,
            entityId: 0,
            securityRoles: [],
            idFe: null
        });
        handleFormUserAsClientOpen();
        if (idFe) {
            const editedEntity = input.userDetails.find(entity => entity.idFe === idFe);
            setEditedUserAsClient(editedEntity);
            setEdit(true);
        };
    };

    const handleEditUserAsProvider = (idFe) => {
        setEditedUserAsProvider({
            id: 0,
            entityType: 3,
            entityId: 0,
            securityRoles: [],
            idFe: null
        });
        handleFormUserAsProviderOpen();
        if (idFe) {
            const editedEntity = input.userDetails.find(entity => entity.idFe === idFe);
            setEditedUserAsProvider(editedEntity);
            setEdit(true);
        };
    };

    return (
        <div className="container">
            <Row className='justify-content-center'>
                <div className='card mt-4 col-8'>
                    <div className="card-header">
                        <h2 className="title mb-0">{intl.get('userDetail.header')}</h2>
                    </div>
                    <div className='card-body'>
                         <ErrorSummary errors={errors} showEverything={false} mode="warning"></ErrorSummary>
                        <PermissionsGate viewScopes={[SCOPES['users.read']]} editScopes={[SCOPES['users.edit']]} viewRoles={[]} editRoles={[]} RenderError={()=>{return <span>{intl.get('permissionsGate')}</span>}}>
                            {
                                loading === true ?
                                <Spinner /> :
                                <Form>
                                    <Row className='mb-4'>
                                        <Col>
                                            <Label for="userEmail" className='margin-label'>{intl.get('userDetail.userEmail')}</Label>
                                            <Input
                                                id="userEmail"
                                                name="email"
                                                placeholder={intl.get('newUser.placeholder.email')}
                                                value={input?.email}
                                                disabled={true}
                                                className={"input-email"}
                                            />
                                        </Col>
                                        <Col>
                                            <Label for="status" className='margin-label'>{intl.get('statusSelect.label')}</Label>
                                            <div className="form-check form-switch switch">
                                                <label className="form-check-label margin-label" htmlFor="flexSwitchCheckDefault">{`${input.active ? intl.get('roleRow.active') : intl.get('roleRow.inactive')}`}</label>
                                                <input className="form-check-input input-switch" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={input.active} onChange={handleStatus} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <span className='fw-bold mb-1'>{intl.get('newUser.assignment.title')}</span>
                                        <span>{intl.get('newUser.assignment.message')}</span>
                                    </Row>
                                    {
                                        isFormUserAsEmployeeOpen && editedUserAsEmployee.entityId !== 0 || isFormUserAsEmployeeOpen && editedUserAsEmployee.securityRoles.length > 0 ?
                                        <FormUserAsEmployee
                                            key={editedUserAsEmployee?.id}
                                            idFe={editedUserAsEmployee?.idFe}
                                            securityRoles={editedUserAsEmployee?.securityRoles}
                                            roles={roles}
                                            entityId={editedUserAsEmployee.entityId}
                                            employees={employees}
                                            handleEmployeeChange={handleEmployeeChange}
                                            handleRoleChange={handleRolesUserAsEmployeeChange}
                                            handleFormUserAsEmployeeOpen={handleFormUserAsEmployeeOpen}
                                            handleSave={handleSave}
                                            edit={edit}
                                        /> :
                                        isFormUserAsEmployeeOpen && editedUserAsEmployee.entityId === 0 || isFormUserAsEmployeeOpen && editedUserAsEmployee.securityRoles.length === 0 ?
                                        <FormUserAsEmployee
                                            key={generateUniqueId()}
                                            idFe={generateUniqueId()}
                                            securityRoles={null}
                                            roles={roles}
                                            entityId={null}
                                            employees={employees}
                                            handleEmployeeChange={handleEmployeeChange}
                                            handleRoleChange={handleRolesUserAsEmployeeChange}
                                            handleFormUserAsEmployeeOpen={handleFormUserAsEmployeeOpen}
                                            handleSave={handleSave}
                                            edit={edit}
                                        /> :
                                        null
                                    }
                                    <Row>
                                        <Label for="userAsEmployee">
                                            {intl.get('newUser.userAsEmployee')}
                                        </Label>
                                    </Row>
                                    {
                                        input.userDetails.filter(comp => comp.entityType === 1).map(comp => (
                                            <UserAsEmployeeEdit
                                                key={comp.idFe}
                                                idFe={comp.idFe}
                                                entityId={comp.entityId}
                                                securityRoles={comp.securityRoles}
                                                employees={employees}
                                                roles={roles}
                                                isFormUserAsEmployeeOpen={isFormUserAsEmployeeOpen}
                                                isFormUserAsClientOpen={isFormUserAsClientOpen}
                                                isFormUserAsProviderOpen={isFormUserAsProviderOpen}
                                                handleEditUserAsEmployee={handleEditUserAsEmployee}
                                                handleDelete={handleDelete}
                                            />
                                        ))
                                    }
                                    <Row>
                                        <Col className='text-md-end addEmployeeSize'>
                                            <HiOutlinePlusCircle className={isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsProviderOpen ? 'me-1 addEmployeeInactive' : 'me-1 addEmployeeActive'}/>
                                            <a type='button' className={isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsProviderOpen ? "addUserEntityDisabled link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" : "link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"} onClick={() => handleEditUserAsEmployee(null)} >{intl.get('newUser.addEmployee')}</a>
                                        </Col>
                                    </Row>
                                    <hr className="separator mt-4"></hr>
                                    {
                                        isFormUserAsClientOpen && editedUserAsClient?.entityId !== 0 || isFormUserAsClientOpen && editedUserAsClient?.securityRoles.length > 0 ?
                                        <FormUserAsClient
                                            key={editedUserAsClient?.id}
                                            idFe={editedUserAsClient?.idFe}
                                            securityRoles={editedUserAsClient?.securityRoles}
                                            roles={roles}
                                            entityId={editedUserAsClient?.entityId}
                                            clients={clients}
                                            handleClientChange={handleClientChange}
                                            handleRoleChange={handleRolesUserAsClientChange}
                                            handleFormUserAsClientOpen={handleFormUserAsClientOpen}
                                            handleSave={handleSaveClient}
                                            edit={edit}
                                        /> :
                                        isFormUserAsClientOpen && editedUserAsClient.entityId === 0 || isFormUserAsClientOpen && editedUserAsClient.securityRoles.length === 0 ?
                                        <FormUserAsClient
                                            key={generateUniqueId()}
                                            idFe={generateUniqueId()}
                                            securityRoles={null}
                                            roles={roles}
                                            entityId={null}
                                            clients={clients}
                                            handleClientChange={handleClientChange}
                                            handleRoleChange={handleRolesUserAsClientChange}
                                            handleFormUserAsClientOpen={handleFormUserAsClientOpen}
                                            handleSave={handleSaveClient}
                                            edit={edit}
                                        /> :
                                        null
                                    }
                                    <Row>
                                        <Label for="userAsClient">
                                            {intl.get('newUser.userAsClient')}
                                        </Label>
                                    </Row>
                                    {
                                        input.userDetails.filter(comp => comp.entityType === 2).map(comp => (
                                            <UserAsClientEdit
                                                key={comp.idFe}
                                                idFe={comp.idFe}
                                                entityId={comp.entityId}
                                                securityRoles={comp.securityRoles}
                                                clients={clients}
                                                roles={roles}
                                                isFormUserAsEmployeeOpen={isFormUserAsEmployeeOpen}
                                                isFormUserAsClientOpen={isFormUserAsClientOpen}
                                                isFormUserAsProviderOpen={isFormUserAsProviderOpen}
                                                handleEditUserAsClient={handleEditUserAsClient}
                                                handleDelete={handleDelete}
                                            />
                                        ))
                                    }
                                    <Row>
                                        <Col className='text-md-end addEmployeeSize'>
                                            <HiOutlinePlusCircle className={isFormUserAsClientOpen || isFormUserAsEmployeeOpen || isFormUserAsProviderOpen ? 'me-1 addEmployeeInactive' : 'me-1 addEmployeeActive'}/>
                                            <a type='button' className={isFormUserAsClientOpen || isFormUserAsEmployeeOpen || isFormUserAsProviderOpen ? "addUserEntityDisabled link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" : "link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"} onClick={() => handleEditUserAsClient(null)}>{intl.get('newUser.addClient')}</a>
                                        </Col>
                                    </Row>
                                    <hr className="separator mt-4"></hr>
                                    {
                                        isFormUserAsProviderOpen && editedUserAsProvider?.entityId !== 0 || isFormUserAsProviderOpen && editedUserAsProvider?.securityRoles.length > 0 ?
                                        <FormUserAsProvider
                                            key={editedUserAsProvider?.id}
                                            idFe={editedUserAsProvider?.idFe}
                                            securityRoles={editedUserAsProvider?.securityRoles}
                                            roles={roles}
                                            entityId={editedUserAsProvider?.entityId}
                                            providers={providers}
                                            handleProviderChange={handleProviderChange}
                                            handleRoleChange={handleRolesUserAsProviderChange}
                                            handleFormUserAsProviderOpen={handleFormUserAsProviderOpen}
                                            handleSave={handleSaveProvider}
                                            edit={edit}
                                        /> :
                                        isFormUserAsProviderOpen && editedUserAsProvider.entityId === 0 || isFormUserAsProviderOpen && editedUserAsProvider.securityRoles.length === 0 ?
                                        <FormUserAsProvider
                                            key={generateUniqueId()}
                                            idFe={generateUniqueId()}
                                            securityRoles={null}
                                            roles={roles}
                                            entityId={null}
                                            providers={providers}
                                            handleProviderChange={handleProviderChange}
                                            handleRoleChange={handleRolesUserAsProviderChange}
                                            handleFormUserAsProviderOpen={handleFormUserAsProviderOpen}
                                            handleSave={handleSaveProvider}
                                            edit={edit}
                                        /> :
                                        null
                                    }
                                    <Row>
                                        <Label for="userAsProvider">
                                            {intl.get('newUser.userAsProvider')}
                                        </Label>
                                    </Row>
                                    {
                                        input.userDetails.filter(comp => comp.entityType === 3).map(comp => (
                                            <UserAsProviderEdit
                                                key={comp.idFe}
                                                idFe={comp.idFe}
                                                entityId={comp.entityId}
                                                securityRoles={comp.securityRoles}
                                                providers={providers}
                                                roles={roles}
                                                isFormUserAsEmployeeOpen={isFormUserAsEmployeeOpen}
                                                isFormUserAsClientOpen={isFormUserAsClientOpen}
                                                isFormUserAsProviderOpen={isFormUserAsProviderOpen}
                                                handleEditUserAsProvider={handleEditUserAsProvider}
                                                handleDelete={handleDelete}
                                            />
                                        ))
                                    }
                                    <Row>
                                        <Col className='text-md-end addEmployeeSize'>
                                            <HiOutlinePlusCircle className={isFormUserAsProviderOpen || isFormUserAsEmployeeOpen || isFormUserAsClientOpen ? 'me-1 addEmployeeInactive' : 'me-1 addEmployeeActive'}/>
                                            <a type='button' className={isFormUserAsProviderOpen || isFormUserAsEmployeeOpen || isFormUserAsClientOpen ? "addUserEntityDisabled link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" : "link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"} onClick={() => handleEditUserAsProvider(null)}>{intl.get('newUser.addProvider')}</a>
                                        </Col>
                                    </Row>
                                    <div className='d-flex justify-content-end my-4'>
                                        <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                            <HiArrowLeft/>
                                        </button>
                                    </div>
                                </Form>
                            }
                        </PermissionsGate>
                    </div>
                </div>
            </Row>
		</div>
    )
}

export default UserDetail;
import React, { ChangeEvent, FC } from 'react';
import { Form, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import PersonaTypeSelect from './PersonaTypeSelect';
import '../../scss/AddressForm.scss';
import intl from 'react-intl-universal';
import ErrorSummary from 'components/common/ErrorSummary';
import { CorporateEntity } from 'axiosApi/models/corporate-entity';
import { PersonaTypeOption } from './Persona';

type PersonaFormProps = {
    errors: any,
    input:   any,  
    handleInputChange:  (e : ChangeEvent<HTMLInputElement> ) => void,
    handleTaxIdChange:  (e : ChangeEvent<HTMLInputElement> ) => void,
    handleNameChange: (e : ChangeEvent<HTMLInputElement> ) => void,
    handlePersonaTypeChange:  (e : PersonaTypeOption) => void,
    handleCorporateEntityChange: (e : ChangeEvent<HTMLInputElement>, id:number ) => void, 
    selectedPersonaType: number,
    personaTypes: any,
    corporateEntities: CorporateEntity[]
}

const PersonaForm: FC<PersonaFormProps> = (props) => {
    const { errors, input, handleInputChange, handleTaxIdChange, handleCorporateEntityChange,
            handleNameChange, selectedPersonaType, personaTypes, handlePersonaTypeChange, corporateEntities
          } = props
    return (
        <Form>
            <FormGroup>
                <Row>
                    <Col md={12}>
                        <ErrorSummary errors={errors} showEverything={true} ></ErrorSummary>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Label for="name" className={`${errors.name ? 'text-danger' : ''}`}>
                            {intl.get('personaCompact.table.thead.name')}* {errors.name && (<span className='text-danger'>{errors.name}</span>)}
                        </Label>
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            placeholder={intl.get('personaCompact.table.thead.name')}
                            value={input.name || ''}
                            onChange={handleNameChange}
                        />
                    </Col>
                    <Col md={6}>
                        <Label for="personaType" className={`${errors.idPersonaType ? 'text-danger' : ''}`}>
                            {intl.get('personaCompact.table.thead.type')}* {errors.idPersonaType && (<span className='text-danger'>{errors.idPersonaType}</span>)}
                        </Label>
                        <PersonaTypeSelect
                            handlePersonaTypeChange={handlePersonaTypeChange}
                            selectedPersonaType={selectedPersonaType}
                            PersonaTypes={personaTypes}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col md={6}>
                        <Label for="taxID" className={`${errors.taxID ? 'text-danger' : ''}`} >
                            {intl.get('personaCompact.table.thead.taxId') }* {errors.taxID && (<span className='text-danger'>{errors.taxID}</span>)}
                        </Label>
                        <Input
                            type="text"
                            id="taxID"
                            name="taxID"
                            placeholder={intl.get('personaCompact.table.thead.taxId')}
                            value={input.taxID || ''}
                            onChange={handleTaxIdChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <hr />
            <FormGroup>
                <Row>
                    <p>{intl.get("personaForm.label.SelectBillTo")}*</p>
                    {corporateEntities.map((entity, index) => (   
                        <Col md={6} key={index} >
                            <Input
                                type="checkbox"
                                id={`checkboxInput-${index}`}
                                name={`checkboxInput-${index}`}
                                value={entity.id}
                                onChange={(e) => handleCorporateEntityChange(e, entity.id)}
                                checked={input.corporateEntitiesIds.includes(entity.id)}
                            />
                            <Label for={`checkboxInput-${index}`} className="ml-2" style={{ marginLeft: '10px' }}>
                                {entity.name} 
                            </Label>
                        </Col>
                    ))}
                </Row>
            </FormGroup>
        </Form>
    )
}

export default PersonaForm;
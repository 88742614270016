import { Autocomplete, TextField } from '@mui/material'
import { Settings } from 'common/settings';
import React, { useEffect, useState } from 'react'
import intl from "react-intl-universal";

type LabelKeySelectorProps = {
    ref?: React.RefObject<HTMLDivElement>;
    labelKey?: string;
    setLabelKey?: (labelKey: string) => void;
    value?: string;
    onChange?: (labelKey: string) => void;
    label?: string;
    size?: 'small' | 'medium';
    className?: string;
    inputClassName?: string;
}


// Function to fetch locales from the API
type LocaleItem = {
    [key: string]: string;
};

export default function LabelKeySelector({
    ref,
    label,
    labelKey,
    setLabelKey,
    size = 'small',
    className: autocompleteClassName,
    inputClassName,
}: LabelKeySelectorProps) {
    const [localeKeys, setLocaleKeys] = React.useState<string[]>([]);
    async function fetchLocaleKeys() {
        try {
          const response = await fetch(  Settings.getInstance().BASE_API_URL_LOCALE + '/api/v1/Locale');
          if (!response.ok) {
            throw new Error('Failed to fetch locales');
          }
          const data = await response.json(); 
          const languages = Object.keys(data);
          const keys = new Set<string>();
          languages.forEach((language) => {
            const langKeys = Object.keys(data[language]);
            langKeys.forEach((key) => keys.add(key));
          });
          return keys;
        } catch (error) {
          console.error('Error fetching locales:', error);
          return [];
        }
    }

    const [value, setValue] = useState<string | null>(labelKey || null);

    useEffect(() => {
        fetchLocaleKeys().then((keys) => setLocaleKeys([...keys]));
    }, []);

    useEffect(() => {
        labelKey && setValue(labelKey);
    }, []);

    useEffect(() => {
        setLabelKey && setLabelKey(value || '');
    }, [value]);

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            ref={ref}
            options={localeKeys}
            getOptionLabel={(option: string) => `${intl.get(option)} (${option})`}
            filterOptions={(options, params) => {
                if(params.inputValue.length < 3) return [];
                const filtered = options.filter((option) => option.toLowerCase().includes(params.inputValue.toLowerCase()));
                return filtered;
            }}
            className={`${autocompleteClassName}`}
            noOptionsText={intl.get('labelKeySelector.noOptionsText')}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} className={`${inputClassName}`} size={size} label={label || intl.get('labelKeySelector.label')} variant='outlined' />}
        />
    )
}

import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import { addMonths, subMonths, isSameDay } from 'date-fns';
import messages from '../../fixture/calendarMessages';
import '../../scss/Time Off/GenericCalendar.scss';
import ModalCalendarEvent from './ModalCalendarEvent';
import intl from 'react-intl-universal';

const local = navigator.language;

const languageStorage = localStorage.language;

let language;
if (!languageStorage) {
    if (local === 'es-ES') {
        language = 'es';
    } else {
        language = 'en-US';
    }
} else if (languageStorage === 'en') {
    language = languageStorage + '-US';
} else {
    language = languageStorage
};

const locales = {
    'en-US': enUS,
    'es': es
};
  
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const GenericCalendar = ({ events, nonWorkingDay = false, isCalendarDashboard = false, updateEvents }) => {

    const [selectedEvent, setSelectedEvent] = useState(null);

    const [isEventDetailOpen, setIsEventDetailOpen] = useState(false);

    const [back, setBack] = useState(true);

    const [next, setNext] = useState(true);

    const [hasEventInPreviousMonth, setHasEventInPreviousMonth] = useState(false);

    useEffect(() => {
        if (events) {
            checkEvents(events, setHasEventInPreviousMonth);
        }
    }, [events]);

    const closeEventDetail = () => {
        setIsEventDetailOpen(false);
        setSelectedEvent(null);
    };

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setIsEventDetailOpen(true);
    };

    const checkEvents = (events, setHasEventInCurrentMonth) => {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
    
        const result = events.some(event => {
            const fromDate = new Date(event.start);
            const toDate = new Date(event.end);
    
            return (fromDate.getMonth() < currentMonth && fromDate.getFullYear() === currentYear) &&
                   (toDate.getMonth() === currentMonth && toDate.getFullYear() === currentYear);
        });
        setHasEventInCurrentMonth(result);
    };

    const eventPropGetter = (event) => {
        const statusColorDict = {
           "Observado" : "bg-secondary",
           "Pendiente": "bg-warning",
           "Aprobado":  "bg-success",
           "Reabrir": "bg-purple",
           "Rechazado": "bg-danger",
           "Abierto" :"bg-primary",
           "Estado 1- wf2": "bg-danger"
        }    
        const bgColor = statusColorDict[event.workflowStateName];   

        
       return {
            style: { backgroundColor: event.workflowStateName == "Reabrir" ? "#6f42c1" : ""  },
           className: bgColor ? bgColor : "", 
       };
   }
   
    const CustomToolbar = (toolbar) => {

        useEffect(() => {
            if (hasEventInPreviousMonth) {
                setBack(true);
                setNext(true);
                const date = new Date();
                const threeMonthsLater = addMonths(new Date(date), 3);
                const oneMonthBefore = subMonths(new Date(date), 1);
                if (isSameDay(toolbar.date, threeMonthsLater)) {
                    setNext(false);
                };
                if (isSameDay(toolbar.date, oneMonthBefore)) {
                    setBack(false);
                };
            } else {
                setBack(false);
                setNext(true);
                const date = new Date();
                const toolbarDate = toolbar.date;
                const threeMonthsLater = addMonths(new Date(date), 3);
                if (isSameDay(toolbarDate, threeMonthsLater)) {
                    setNext(false);
                };
                if (toolbarDate.getMonth() > date.getMonth()) {
                    setBack(true);
                };
            };
        }, [toolbar.date, hasEventInPreviousMonth]);

        return (
            <div className="rbc-toolbar">
                <span className="rbc-btn-group">
                    {
                        isCalendarDashboard ?
                        <>
                            <button type="button" onClick={() => toolbar.onNavigate('TODAY')}>{intl.get('genericCalendar.customToolbar.button.today')}</button>
                            {
                                back &&
                                <button type="button" onClick={() => toolbar.onNavigate('PREV')}>{intl.get('genericCalendar.customToolbar.button.back')}</button>
                            }
                            {
                                next &&
                                <button type="button" onClick={() => toolbar.onNavigate('NEXT')}>{intl.get('genericCalendar.customToolbar.button.next')}</button>
                            } 
                        </>
                        :
                        <>
                            <button type="button" onClick={() => toolbar.onNavigate('TODAY')}>{intl.get('genericCalendar.customToolbar.button.today')}</button>
                            <button type="button" onClick={() => toolbar.onNavigate('PREV')}>{intl.get('genericCalendar.customToolbar.button.back')}</button>
                            <button type="button" onClick={() => toolbar.onNavigate('NEXT')}>{intl.get('genericCalendar.customToolbar.button.next')}</button>
                        </>
                    }
                </span>
                <span className="rbc-toolbar-label">{toolbar.label}</span>
            </div>
        );
    };
    
    return (
        <>
            <Calendar
                localizer={localizer}
                events={events ? events : []}
                culture={language}
                messages={language === 'es' ? messages : null}
                style={isCalendarDashboard ? { height: '35rem' } : { height: '40rem' }}
                onSelectEvent={nonWorkingDay ? handleSelectEvent : null}
                views={["month"]}
                components={{
                    toolbar: CustomToolbar,
                }}
                popup
                eventPropGetter={eventPropGetter}
            />
            {
                nonWorkingDay &&
                <ModalCalendarEvent 
                    isOpen={isEventDetailOpen} 
                    closeModal={closeEventDetail} 
                    eventId={selectedEvent?.id} 
                    updateEvents={updateEvents}
                />
            }
        </>
    )
}

export default GenericCalendar;
import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, FormGroup, InputLabel, Stack, TextField, Typography } from '@mui/material'
import intl from 'react-intl-universal'
import Api from '../../../axiosApi/api'
import { useApi } from '../../../api/ApiProvider'
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider'
import ChipSelector from './ChipSelector'
import '../../../scss/Workflow/EditableNodeDrawer.scss'
import { FaSave, FaTrash } from 'react-icons/fa'
import { handleAPIError } from 'common/errorHandler';
import ErrorSummary from 'components/common/ErrorSummary'
import { Scope } from "axiosApi/models/scope";
import LabelKeySelector from 'components/LabelKeySelector'
import ColorPicker from 'components/ColorPicker'

type EditableNodeDrawerProps = {
    selectedNode: any
    onClose: () => void
    disabled?: boolean
    saveNode: (node:any) => void
    deleteNode: (nodeId:string) => void
}

const EditableNodeDrawer: React.FC<EditableNodeDrawerProps> = ({ selectedNode, onClose, disabled, saveNode, deleteNode }) => {
    const [name, setName] = useState(selectedNode?.data?.name || '')
    const [labelKey, setLabelKey] = useState(selectedNode?.data?.labelKey || '')
    const [final, setFinal] = useState(selectedNode?.data?.final || false)
    const [initial, setInitial] = useState(selectedNode?.data?.initial || false)
    const [color, setColor] = useState(selectedNode?.data?.color || '#fff')
    const [errors, setErrors] = useState(null);

    const [scopes, setScopes] = useState<Scope[]>([]);

    
    const [loading, setLoading] = useState(true);
    const [editScopes, setEditScopes] = useState<string[]>(selectedNode?.data?.editScopes || []);
    const [viewScopes, setViewScopes] = useState<string[]>(selectedNode?.data?.viewScopes || []);
    const [deleteScopes, setDeleteScopes] = useState<string[]>(selectedNode?.data?.deleteScopes || []);
    const api: Api = useApi();
    const toast = useToastMessageQueue();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
    const handleLabelKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => setLabelKey(event.target.value);
    const handleFinalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFinal(event.target.checked);
        setInitial(false);
    }
    const handleInitialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitial(event.target.checked);
        setFinal(false);
    }
    const handleEditScopesChange = (newValue: string[]) => {
        setEditScopes(newValue);
    }
    const handleViewScopesChange = (newValue: string[]) => {
        console.log(newValue);
        setViewScopes(newValue);
    }
    const handleDeleteScopesChange = (newValue: string[]) => setDeleteScopes(newValue)
    useEffect(() => {
        setName(selectedNode?.data?.name || '');
        setLabelKey(selectedNode?.data?.labelKey || '');
        setFinal(selectedNode?.data?.final || false);
        setInitial(selectedNode?.data?.initial || false);
        setEditScopes(selectedNode?.data?.editScopes || []);
        setViewScopes(selectedNode?.data?.viewScopes || []);
        setDeleteScopes(selectedNode?.data?.deleteScopes || []);
        setColor(selectedNode?.data?.color || '#fff');
    }, [selectedNode])

    const fetchScopes = () => {
        setLoading(true);
        api.scopeApi.apiVversionScopeAllGet('1', 1, 100, {})
        .then((response) => {
            if (response.data.data === null) {
                setScopes(response.data.data.queryResult);
            } else {
                setScopes(response.data.data.queryResult);
            }
            setLoading(false)
            console.log('Success')
        })
        .catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        })
        .finally(() => {
            setLoading(false)
        });
    }

    const handleSave = () => {
        setErrors({})
        if (!name) {
            setErrors({ summary: ["Name is required"] })
            return
        }
        saveNode({
            ...selectedNode,
            data: {
                ...selectedNode.data,
                name,
                labelKey,
                final,
                initial,
                color,
                editScopes : editScopes,
                viewScopes : viewScopes,
                deleteScopes : deleteScopes,
            }
        })
    }

    const handleDelete = (nodeId: string) => {
        deleteNode && deleteNode(nodeId)
    };

    useEffect(() => {
        fetchScopes()
    }, [])
    
    return (
        <Drawer anchor="right" open={!!selectedNode} onClose={onClose} classes={{ paper: 'drawer p-4' }}>
            <Typography variant="h4" color="primary">
                {selectedNode?.id ? 'Edit' : 'Create'} Node
            </Typography>
            <Stack spacing={2} mt={2} mb={2}>
                <ErrorSummary errors={errors} />
                <TextField
                    label={intl.get('editableNode.placeholder.nodeName')}
                    size="small"
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    disabled={disabled}
                    autoFocus
                />
                <LabelKeySelector
                    label={intl.get('editableNode.placeholder.labelKey')}
                    size="small"
                    labelKey={labelKey}
                    setLabelKey={setLabelKey}
                    className="w-100"
                />
                <Box display={"flex"} flexDirection={'row'} gap={2}>
                    <Box>
                        <InputLabel>{intl.get('editableNode.placeholder.color')}</InputLabel>
                        <ColorPicker color={color} setColor={setColor} />
                    </Box>
                </Box>
                <FormGroup className='d-flex flex-row justify-content-center align-items-center gap-4'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={initial?true:false}
                                onChange={handleInitialChange}
                                disabled={disabled}
                                name="initial"
                            />
                        }
                        label={intl.get('editableNode.placeholder.start')}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleFinalChange}
                                checked={final?true:false}
                                name="final"
                                disabled={disabled}
                            />
                        }
                        label={intl.get('editableNode.placeholder.final')}
                    />
                </FormGroup>
                <ChipSelector
                    label="View Scopes"
                    value={viewScopes.map((x) => ({ id: parseInt(x), description: '' }))}
                    onChange={handleViewScopesChange}
                    scopes={scopes}
                />
                <ChipSelector
                    label="Edit Scopes"
                    value={editScopes.map((x) => ({ id: parseInt(x), description: '' }))}
                    onChange={handleEditScopesChange}
                    scopes={scopes}
                />
                <ChipSelector
                    label="Delete Scopes"
                    value={deleteScopes.map((x) => ({ id: parseInt(x), description: '' }))}
                    onChange={handleDeleteScopesChange}
                    scopes={scopes}
                />
                <Box display={"flex"} gap={2} sx={{width: 'fit-content', alignSelf: 'end'}}>
                    <Button startIcon={<FaTrash/>} variant="contained" color="error" onClick={() => handleDelete(selectedNode?.id)} sx={{width: 'fit-content', alignSelf: 'end'}}>
                        {intl.get('delete.button')}
                    </Button>
                    <Button startIcon={<FaSave/>} variant="contained" color="primary" onClick={() => handleSave()} sx={{width: 'fit-content', alignSelf: 'end'}}>
                        {intl.get('save.modal.save.button')}
                    </Button>
                </Box>
            </Stack>
        </Drawer>
    )
}

export default EditableNodeDrawer

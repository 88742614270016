import React from 'react';
import { getStraightPath, InternalNode, Position } from '@xyflow/react';
import { getEdgeParams } from '../utils-ts';

interface FloatingConnectionLineProps {
  toX: number;
  toY: number;
  fromPosition: Position;
  toPosition: Position;
  fromNode: any;
}

const FloatingConnectionLine: React.FC<FloatingConnectionLineProps> = ({
  toX,
  toY,
  fromPosition,
  toPosition,
  fromNode,
}) => {
  if (!fromNode) {
    return null;
  }

  const targetNode:InternalNode = {
    id: 'connection-target',
    position:{ x: toX, y: toY },
    data: {},
    measured: {
      width: 1,
      height: 1,
    },
    internals: {
        positionAbsolute: { x: toX, y: toY },
        z: 0,
        userNode: undefined
    },
  };
  
  const { sx, sy } = getEdgeParams(fromNode, targetNode);
  const [edgePath] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    
    targetX: toX,
    targetY: toY,
  });

  return (
    <g>
      <path
        fill="none"
        stroke="#222"
        strokeWidth={1.5}
        className="animated"
        d={edgePath}
      />
      <circle
        cx={toX}
        cy={toY}
        fill="#fff"
        r={3}
        stroke="#222"
        strokeWidth={1.5}
      />
    </g>
  );
};

export default FloatingConnectionLine;

import React, { useContext, useState } from "react";
import { AuthContext } from './authentication/context/authContext';
import intl from 'react-intl-universal';
import Spinner from "./Spinner";
/**
 * Renders a button which, when selected, will open a popup for login
 */
const SignInButton: React.FunctionComponent<{}> = () =>  {

        const authContext = useContext(AuthContext);
        const [loading, setLoading] = useState(false);

        const onClick = () => {
                setLoading(true);
                authContext.signIn();
        }

        return (<>
                {loading && <Spinner />}
                <button className="btn btn-primary btn-lg" onClick={onClick}>
                        {intl.get('singInButton')}
                </button>
        </>)
}

export default SignInButton;
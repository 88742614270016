/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Workflow API
 * Workflows
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { RequestArgs, BaseAPI, RequiredError } from '../base';
import { BooleanResponse } from '../models';
import { CreateCommand } from '../models';
import { DeleteCommand } from '../models';
import { StringResponse } from '../models';
import { UpdateCommand } from '../models';
import { WorkflowResponse } from '../models';
import { UpdateStateCommand } from '../models';
import { WorkflowDetailsResponse } from '../models';

import { WorkflowSearchResponseResponse } from '../models';
import { WorkflowStateDTOSearchResponseResponse } from '../models';
import { WorkflowStateSearchResponseResponse } from '../models';
import { LogEntrySearchResponseResponse, LogEntrySearchResponse, LogEntry } from '../models';
import { Settings } from 'common/settings';
const BASE_PATH = Settings.getInstance().BASE_API_URL_WORKFLOW;

/**
 * WorkflowApi - axios parameter creator
 * @export
 */
export const WorkflowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowAllGet: async (version: string, pageNum?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowAllGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {DeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowDelete: async (version: string, body?: DeleteCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowDelete.');
            }
            const localVarPath = `/api/v{version}/Workflow`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdGet: async (  id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionWorkflowIdGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowIdGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the inital state for a worfklow
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdInitialGet: async (version: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionWorkflowIdInitialGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowIdInitialGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/{id}/initial`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the workflows for a given module Id.
         * @param {string} version 
         * @param {number} [idModule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdModuleGet: async (version: string, idModule?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowIdModuleGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/idModule`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (idModule !== undefined) {
                localVarQueryParameter['idModule'] = idModule;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdScopedGet: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionWorkflowIdScopedGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowIdScopedGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/{id}/scoped`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the possible states given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdStateIdStateGet: async ( version: string, idState: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idState' is not null or undefined
            if (idState === null || idState === undefined) {
                throw new RequiredError('idState','Required parameter idState was null or undefined when calling apiVversionWorkflowIdStateIdStateGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowIdStateIdStateGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/idState/{idState}`
                .replace(`{${"idState"}}`, encodeURIComponent(String(idState)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {number} idModule 
         * @param {number} idEntity 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowLogsIdModuleIdEntityGet: async (idModule: number, idEntity: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idModule' is not null or undefined
            if (idModule === null || idModule === undefined) {
                throw new RequiredError('idModule','Required parameter idModule was null or undefined when calling apiVversionWorkflowLogsIdModuleIdEntityGet.');
            }
            // verify required parameter 'idEntity' is not null or undefined
            if (idEntity === null || idEntity === undefined) {
                throw new RequiredError('idEntity','Required parameter idEntity was null or undefined when calling apiVversionWorkflowLogsIdModuleIdEntityGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowLogsIdModuleIdEntityGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/logs/{idModule}/{idEntity}`
                .replace(`{${"idModule"}}`, encodeURIComponent(String(idModule)))
                .replace(`{${"idEntity"}}`, encodeURIComponent(String(idEntity)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} moduleId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowModuleIdActiveScopedGet: async (moduleId: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            if (moduleId === null || moduleId === undefined) {
                throw new RequiredError('moduleId','Required parameter moduleId was null or undefined when calling apiVversionWorkflowModuleIdActiveScopedGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowModuleIdActiveScopedGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/{moduleId}/active/scoped`
                .replace(`{${"moduleId"}}`, encodeURIComponent(String(moduleId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {CreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowPost: async (version: string, body?: CreateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowPost.');
            }
            const localVarPath = `/api/v{version}/Workflow`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowPut: async (version: string, body?: UpdateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowPut.');
            }
            const localVarPath = `/api/v{version}/Workflow`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the states for a module by module name.
         * @param {string} moduleName 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowStatesByModuleNameModuleNameGet: async (moduleName: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleName' is not null or undefined
            if (moduleName === null || moduleName === undefined) {
                throw new RequiredError('moduleName','Required parameter moduleName was null or undefined when calling apiVversionWorkflowStatesByModuleNameModuleNameGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowStatesByModuleNameModuleNameGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/statesByModuleName/{moduleName}`
                .replace(`{${"moduleName"}}`, encodeURIComponent(String(moduleName)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the possible transitions given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowTransitionsIdStateGet: async (idState: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idState' is not null or undefined
            if (idState === null || idState === undefined) {
                throw new RequiredError('idState','Required parameter idState was null or undefined when calling apiVversionWorkflowTransitionsIdStateGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowTransitionsIdStateGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/transitions/{idState}`
                .replace(`{${"idState"}}`, encodeURIComponent(String(idState)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateStateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowUpdateStatePost: async (version: string, body?: UpdateStateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowUpdateStatePost.');
            }
            const localVarPath = `/api/v{version}/Workflow/updateState`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowApi - functional programming interface
 * @export
 */
export const WorkflowApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowSearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowAllGet(version, pageNum, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {DeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowDelete(version: string, body?: DeleteCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowDelete(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowIdGet( id, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the inital state for a worfklow
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdInitialGet(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowStateSearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowIdInitialGet( version,id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets all the workflows for a given module Id.
         * @param {string} version 
         * @param {number} [idModule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdModuleGet(version: string, idModule?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowDetailsResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowIdModuleGet(version, idModule, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdScopedGet(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowIdScopedGet(id, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the possible states given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdStateIdStateGet(idState: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowStateSearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowIdStateIdStateGet(version, idState, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {number} idModule 
         * @param {number} idEntity 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowLogsIdModuleIdEntityGet(idModule: number, idEntity: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<LogEntrySearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowLogsIdModuleIdEntityGet(idModule, idEntity, version, options);
            
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} moduleId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowModuleIdActiveScopedGet(moduleId: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowModuleIdActiveScopedGet(moduleId, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {CreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowPost(version: string, body?: CreateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowPost(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowPut(version: string, body?: UpdateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowPut(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the states for a module by module name.
         * @param {string} moduleName 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowStateDTOSearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the possible transitions given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowTransitionsIdStateGet(idState: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowStateSearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowTransitionsIdStateGet(idState, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateStateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowUpdateStatePost(version: string, body?: UpdateStateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BooleanResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowUpdateStatePost(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkflowApi - factory interface
 * @export
 */
export const WorkflowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowSearchResponseResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {DeleteCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowDelete(version: string, body?: DeleteCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowDelete(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the inital state for a worfklow
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdInitialGet(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowStateSearchResponseResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowIdInitialGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the workflows for a given module Id.
         * @param {string} version 
         * @param {number} [idModule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdModuleGet(version: string, idModule?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowDetailsResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowIdModuleGet(version, idModule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdScopedGet(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowIdScopedGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the possible states given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdStateIdStateGet(idState: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowStateSearchResponseResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowIdStateIdStateGet(idState, version, options).then((request) => request(axios, basePath));
        },
 /**
         * 
         * @summary Updates an existing Workflow
         * @param {number} idModule 
         * @param {number} idEntity 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
 async apiVversionWorkflowLogsIdModuleIdEntityGet(idModule: number, idEntity: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<LogEntrySearchResponseResponse>> {
    return WorkflowApiFp(configuration).apiVversionWorkflowLogsIdModuleIdEntityGet(idModule, idEntity, version, options).then((request) => request(axios, basePath));
},        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} moduleId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowModuleIdActiveScopedGet(moduleId: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowModuleIdActiveScopedGet(moduleId, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {CreateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowPost(version: string, body?: CreateCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowPost(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowPut(version: string, body?: UpdateCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowPut(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the states for a module by module name.
         * @param {string} moduleName 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName: string, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowStateDTOSearchResponseResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the possible transitions given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowTransitionsIdStateGet(idState: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowStateSearchResponseResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowTransitionsIdStateGet(idState, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateStateCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowUpdateStatePost(version: string, body?: UpdateStateCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<BooleanResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowUpdateStatePost(version, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkflowApi - object-oriented interface
 * @export
 * @class WorkflowApi
 * @extends {BaseAPI}
 */
export class WorkflowApi extends BaseAPI {
    /**
     * 
     * @summary Gets all the items in a list.
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowSearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an existing Workflow
     * @param {string} version 
     * @param {DeleteCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowDelete(version: string, body?: DeleteCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowDelete(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets a detailed workflow .
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowIdGet(version: string, id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the inital state for a worfklow
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowIdInitialGet(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowStateSearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdInitialGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets all the workflows for a given module Id.
     * @param {string} version 
     * @param {number} [idModule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowIdModuleGet(version: string, idModule?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowDetailsResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdModuleGet(version, idModule, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets a detailed workflow .
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowIdScopedGet(version: string,id: number,  options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdScopedGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the possible states given the current state
     * @param {number} idState 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowIdStateIdStateGet(idState: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowStateSearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdStateIdStateGet(idState, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
         * 
         * @summary Updates an existing Workflow
         * @param {number} idModule 
         * @param {number} idEntity 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         * @memberof WorkflowApi
         */
    public async apiVversionWorkflowLogsIdModuleIdEntityGet(idModule: number, idEntity: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<LogEntrySearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowLogsIdModuleIdEntityGet(idModule, idEntity, version, options).then((request) => request(this.axios, this.basePath));
    }  
    /**
     * 
     * @summary Gets a detailed workflow .
     * @param {number} moduleId 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowModuleIdActiveScopedGet(moduleId: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowModuleIdActiveScopedGet(moduleId, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an existing Workflow
     * @param {string} version 
     * @param {CreateCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowPost(version: string, body?: CreateCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowPost(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an existing Workflow
     * @param {string} version 
     * @param {UpdateCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowPut(version: string, body?: UpdateCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowPut(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the states for a module by module name.
     * @param {string} moduleName 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName: string, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowStateDTOSearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the possible transitions given the current state
     * @param {number} idState 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowTransitionsIdStateGet(idState: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowStateSearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowTransitionsIdStateGet(idState, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an existing Workflow
     * @param {string} version 
     * @param {UpdateStateCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowUpdateStatePost(version: string, body?: UpdateStateCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<BooleanResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowUpdateStatePost(version, body, options).then((request) => request(this.axios, this.basePath));
    }
}

import React, { useState, useEffect, useContext } from 'react'
import {parse} from 'url';
import * as cognito from '../../common/cognito'
import { CognitoIdToken, CognitoAccessToken } from 'amazon-cognito-identity-js';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import {MIN_BEFORE_EXPIRATION} from '../../../common/constants';
import { confirmAlert } from 'react-confirm-alert';
import intl from 'react-intl-universal';
import { useNavigate } from 'react-router';

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo?: { username?: string; email?: string; sub?: string; accessToken?: string; refreshToken?: string }
  authStatus?: AuthStatus
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  signIn?: any
  verifyCode?: (string) => void
  getSession?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.SignedOut,
  signIn() {
    console.log('Not loaded yet')
  },
  signOut() {
    console.log('Not loaded yet')
  },
}

type Props = {
  children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

const RETRY_LIMIT = 3;

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState({})
  const toast = useToastMessageQueue();
  const [retries, setRetries] = useState(0);

  const getTokens = async (authCode: string): Promise<boolean> => {
    try{
      const resp = await cognito.getTokens(authCode);
      const tokens = resp.data;

      sessionStorage.setItem('accessToken', `${tokens.access_token}`)
      sessionStorage.setItem('idToken', `${tokens.id_token}`)
      sessionStorage.setItem('refreshToken', `${tokens.refresh_token}`)
      sessionStorage.setItem('tokenExpires', `${tokens.expires_in}`)
      setSessionInfo({
        idToken: tokens.id_token,
        accessToken: tokens.access_token,
        refreshToken: tokens.refresh_token,
      })
      const AccessToken = new CognitoAccessToken({ AccessToken: tokens.accessToken });
      const IdToken = new CognitoIdToken({ IdToken: tokens.id_token });
      sessionStorage.setItem('username', IdToken.payload.email);
      
      setAuthStatus(AuthStatus.SignedIn);
      setAuthCode(null);
      return true;
    }catch(err){
      console.error(err);
      setRetries((prev) => prev + 1);
      return false;
    }
  }

  const [authCode, setAuthCode] = useState(null);
  const validateCognitoToken: (authCode: string) => void = async (authCode: string) => {
    if(AuthStatus.SignedOut === authStatus){
      setAuthStatus(AuthStatus.Loading);
      setAuthCode(authCode);
      setRetries(0);
    }else if(AuthStatus.SignedIn === authStatus){
      console.log("Already signed in");
    }else{
      console.log("Auth loading");
    }
  }

  useEffect(() => {
    if(authCode && retries <= RETRY_LIMIT){
      getTokens(authCode).then((valid) => {
        if(valid){
          setRetries(0);
        }
      });
    }else if(retries > RETRY_LIMIT){
      toast.error({ header: 'Error', body:  intl.get('login.tokens.failed')});
      setRetries(0);
      signOut();
      setAuthCode(null);
    }

  }, [authCode, retries])

  useEffect(() => {
    async function getSessionFromStorage() {
      let tokens:any;  
      try {
        console.debug("Getting session info");
        if(sessionStorage.getItem('accessToken')!=null)
        {
          tokens = await getSession();
          if(!!tokens && !!tokens.accessToken)
          {
              console.debug("Getting tokens from session")
              setAuthStatus(AuthStatus.SignedIn)
              sessionStorage.setItem("expiration_handle_id", setToHappen(()=>{ handleSessionEnding()}, parseJwt(tokens.accessToken).exp - MIN_BEFORE_EXPIRATION).toString());
          }else{
            signOut();
          }
        }else{
          signOut();
        }
      } catch (err) {
        console.log(err);
        toast.error({ header: 'Error', body:  intl.get('login.userinfo.failed')});
        signOut();
      }
    }
    getSessionFromStorage()
  }, [setAuthStatus, authStatus, toast, setSessionInfo, retries])

  function setToHappen(fn, date): number {
    console.debug(`Setting ${fn.toString()} to happen in ${date * 1000 - Date.now()} ms. `);
    return window.setTimeout(fn, date * 1000 - Date.now());
  } 

  const handleSessionEnding = () => {
    const timeoutId =  setTimeout(()=>{
      signOut();
      window.location.href="/";
    }, 60000 );

    confirmAlert({
      title: intl.get('login.expiring.title'),
      message: intl.get('login.expiring.message'),
      buttons: [
          {
            label: intl.get('login.expiring.button.continue'),
            onClick: async () => {
              cognito.refreshTokens(sessionStorage.getItem('refreshToken')).then((resp)=>
              {
                  let tokens = JSON.parse(resp.data.result);   
                  sessionStorage.setItem('accessToken', `${tokens.access_token}`)
                  sessionStorage.setItem('idToken', `${tokens.id_token}`)
                  sessionStorage.setItem('tokenExpires', `${tokens.expires_in}`)
                  setSessionInfo({
                    idToken: tokens.id_token,
                    accessToken: tokens.access_token,
                  })
                  setAuthStatus(AuthStatus.SignedIn);
                  clearTimeout(timeoutId);
                  sessionStorage.setItem("expiration_handle_id", setToHappen(()=>{ handleSessionEnding()}, parseJwt(tokens.access_token).exp - MIN_BEFORE_EXPIRATION).toString());

              })
              .catch((err)=>{
                toast.error({ header: 'Error', body:  intl.get('login.tokens.failed')});
                signOut();
                window.location.href="/";
              });
            },
          }
        ]
    });
  };

  function signIn(){
    console.debug("Signing in");
    cognito.signIn();
  }

  function signOut() {
    if(authStatus === AuthStatus.SignedOut)
    {
      console.debug("Already signed out");
      return;
    }
    
    console.debug("Signing out");
    setAuthStatus(AuthStatus.SignedOut);
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('idToken');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem("expiration_handle_id");
    sessionStorage.removeItem('UserEntity');
    sessionStorage.removeItem('Users');
    sessionStorage.removeItem('authCode');
    sessionStorage.removeItem('tokenExpires');
  }

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  function getSession() {
    console.debug("Getting session data");
    try {
      const session = {
        idToken: sessionStorage.getItem("idToken"),
        accessToken: sessionStorage.getItem("accessToken"),
        refreshToken: sessionStorage.getItem("refreshToken")
      }
      var tokens;
      if (!session.idToken && !session.accessToken){
        // moved to authorization code handleImplicitFlow(window.location.href);
      } else { 
        tokens = session;
      }
      return tokens;
    } catch (err) {
      throw err
    }
  }

  return <AuthContext.Provider value={{
    authStatus,
    sessionInfo,
    signIn,
    signOut,
    verifyCode: validateCognitoToken
  }}>
    {children}
  </AuthContext.Provider>
}

export default AuthProvider

import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { FaSearch } from "react-icons/fa";
import { Link } from 'react-router';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { HiOutlinePlus } from 'react-icons/hi';
import InvoicesSearchBar from './InvoicesSearchBar';
import InvoicesStatusSelect from './InvoicesStatusSelect';
import DateRangePickerDialog from 'components/DateRangePickerDialog';

const InvoicesNavbar = ({ onSearch, invoiceStatus }) => {

    const [queryString, setQueryString] = useState<string>('');
    const [idStatus, setIdStatus] = useState(null);
    const [range, setRange] = useState(null);

    const handleSearchButton = () => {
        onSearch(queryString, idStatus, range);
        setRange(null);
    };

    const handleStatusChange = (e) => {
        if (e !== null) {
            setIdStatus(e.id);
        } else {
            setIdStatus(null);
        }
    };

    return (
        <nav className='navbar container-fluid'>
            <div className='col-11 filters gap-3'>
                <InvoicesSearchBar queryString={queryString} setQueryString={setQueryString}/>
                <DateRangePickerDialog
                    disabled={false}
                    inputName="dateRange"
                    className='timesheet-datepicker'
                    selected={range}
                    setRange={setRange}
                    required
                />
                <InvoicesStatusSelect invoiceStatus={invoiceStatus} selectedStatus={idStatus} handleStatusChange={handleStatusChange} isClearable={true} />
                <button type="button" className="btn btn-outline-primary" onClick={handleSearchButton} ><FaSearch className="mb-1"></FaSearch>&nbsp;Search</button>
            </div>
            <div className='col-1 newRole'>
                <Link to= '/'><button className='btn btn-primary p-1 plusButton' type='button' id='newInvoice' data-tooltip-id='tooltipButton'><HiOutlinePlus className='plusIcon'/></button></Link>
                <ReactTooltip 
                    id='tooltipButton'
                    place='left'
                    content="New Invoice"
                />
            </div>
        </nav>
    )
}

export default InvoicesNavbar;
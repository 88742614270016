import React, { useContext } from "react"
import { AuthCheck } from "./AuthCheck"
import { AuthContext, AuthStatus } from "components/authentication/context/authContext"
import SecurityWrapperRoutes from "components/SecurityWrapperRoutes"
import { Navigate, Route, Routes } from "react-router"
import { PageLogin } from "components/PageLogin"
import { Verify } from "components/Verify"
import ErrorTemplateFoundPage from "../ErrorTemplatePage"
import intl from 'react-intl-universal';

export default function BerpRoutes() {
    const { authStatus } = useContext(AuthContext);
    
    return (
        <>
        <AuthCheck status={AuthStatus.SignedIn}>
            <SecurityWrapperRoutes />
        </AuthCheck>
        <AuthCheck status={AuthStatus.SignedOut}>
            <Routes>
                <Route path="/login" element={<PageLogin />} />
                <Route path="/verify" element={<Verify />} />
                <Route path="*" element={authStatus === AuthStatus.SignedOut ? <Navigate to="/login" /> : <ErrorTemplateFoundPage statusCode={404} title={intl.get('notFoundPage.title')} />} />
            </Routes>
        </AuthCheck>
        </>
    )
}

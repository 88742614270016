import React from 'react';
import { useNavigate } from 'react-router';
import { Client } from 'axiosApi/models';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import intl from 'react-intl-universal';

type projectRowProps = {
    id: number,
    name: string,
    client: Client,
    className: string
}
const ProjectRow = ({ id, name, client, className }: projectRowProps) => {

    const navigate = useNavigate();

    const handleClickRow = () => {
        navigate('/project/detail/' + id);
    };

    return (
        <>
            {
                name ?
                <tr className={className}>
                    <td>{name}</td>
                    <td>{client.name}</td>
                    <td className='text-center'>
                        <a className='td-icon mx-1' onClick={handleClickRow} title={intl.get('roleRow.editButton.title')}>
                            <HiOutlinePencilAlt/>
                        </a>
                    </td>
                </tr> :
                null
            }
        </>
    )
}

export default ProjectRow;
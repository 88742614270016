import React, { Dispatch, FC, SetStateAction } from "react";
import Modal, { ModalSizes } from "../Modal";
import PersonaFC from "./Persona";
import { Persona } from "axiosApi/models/persona";
import { CorporateEntity } from "axiosApi/models/corporate-entity";
import CorporateEntities from "components/Corporate Entities/CorporateEntities";

type ModalPersonaProps = {
    isOpen: boolean;
    closeModal: () => void;
    persona: Persona;
    setPersona:  Dispatch<SetStateAction<Persona>>;
    inputProvider?: any;
    setInputProvider?: (value: any) => void;
    setPersonaExist: Dispatch<SetStateAction<boolean>>;
    entityId : number;
    idEntityType : number;
    corporateEntities : CorporateEntity[];
    personaExists: boolean
  }
  

 const ModalPersona : FC<ModalPersonaProps> = (props) => {
    
    const {persona, corporateEntities ,isOpen, closeModal, setPersona, inputProvider, setInputProvider, personaExists, setPersonaExist, entityId, idEntityType} = props        
    
    return (
         <Modal isOpen={isOpen} closeModal={closeModal} size={ModalSizes.small} height="auto">
             <PersonaFC 
                closeModal={closeModal} 
                persona={persona} 
                setPersona={setPersona} 
                entityId = {entityId}
                idEntityType = {idEntityType}
                inputProvider={inputProvider}
                corporateEntities={corporateEntities}       
                setInputProvider={setInputProvider} 
                setPersonaExist={setPersonaExist}
                personaExists={personaExists}/>
         </Modal>
   );
 };

 export default ModalPersona;
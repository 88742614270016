import { useEffect, useState } from 'react';


 export class Settings {
    
    private static instance: Settings
     BASE_API_URL:string;
     BASE_API_URL_CLIENTS:string;
     BASE_API_URL_ADDRESS:string;
     BASE_API_URL_JOBS:string; 
     BASE_API_URL_CONTACTINFO:string;
     BASE_API_URL_EMPLOYEE:string; 
     BASE_API_URL_PROJECT:string;
     BASE_API_URL_TIMESHEET:string;
     BASE_API_URL_REPORTS:string;
     BASE_API_URL_TIMEOFF:string;
     BASE_API_URL_WORKFLOW:string;     
     BASE_API_URL_INVOICE:string;     
     BASE_API_URL_LOCALE:string;
     USER_POOL_ID:string; 
     CLIENT_ID:string; 
     LOGIN_DOMAIN:string;
     REDIRECT_URL:string;
     RESPONSE_TYPE:string;
    
     private constructor() {
        this.init();
    }

    async init(){
        const request = new XMLHttpRequest();
        const settingsFile = "/settings"+(!!window && window.location.href.indexOf("localhost")>0?"-dev":"")+".json";
        request.open('GET', settingsFile, false);  // `false` makes the request synchronous
        request.send(null);
        if (request.status !== 200 ) {

            console.log("Settings not found");
            return;
        }

        const data = JSON.parse(request.responseText);
        this.BASE_API_URL = data.BASE_API_URL;
        this.BASE_API_URL_CLIENTS = this.BASE_API_URL + data.URL_CLIENTS;
        this.BASE_API_URL_ADDRESS = this.BASE_API_URL +data.URL_ADDRESS;
        this.BASE_API_URL_JOBS = this.BASE_API_URL +data.URL_JOBS;
        this.BASE_API_URL_CONTACTINFO = this.BASE_API_URL +data.URL_CONTACTINFO;
        this.BASE_API_URL_EMPLOYEE = this.BASE_API_URL +data.URL_EMPLOYEE;
        this.BASE_API_URL_PROJECT = this.BASE_API_URL +data.URL_PROJECT;
        this.BASE_API_URL_TIMESHEET = this.BASE_API_URL +data.URL_TIMESHEET;
        this.BASE_API_URL_REPORTS = this.BASE_API_URL +data.URL_REPORTS;
        this.BASE_API_URL_TIMEOFF = this.BASE_API_URL +data.URL_TIMEOFF;
        this.BASE_API_URL_WORKFLOW = this.BASE_API_URL + data.URL_WORKFLOW;
        this.BASE_API_URL_INVOICE = this.BASE_API_URL + data.URL_INVOICE;
        this.BASE_API_URL_LOCALE = this.BASE_API_URL +data.URL_EMPLOYEE;
        this.USER_POOL_ID = data.USER_POOL_ID;
        this.CLIENT_ID = data.CLIENT_ID;
        this.LOGIN_DOMAIN = data.LOGIN_DOMAIN;
        this.REDIRECT_URL = data.REDIRECT_URL;
        this.RESPONSE_TYPE = data.RESPONSE_TYPE;
    }

    
    public static getInstance(): Settings {
        
      if (!Settings.instance) {
        Settings.instance = new Settings()
      }
    
      return Settings.instance
    }
 }

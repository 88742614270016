import React, { useContext, useEffect } from "react";
import { Button, Container, Toast, ToastBody, ToastHeader } from "reactstrap";
import { ToastMessageQueueContext } from './ToastMessageProvider';
import 'scss/ToastMessageQueue.scss'

export type ToastType = 'error' | 'success' | 'info' | 'warning';
export type ToastPosition = 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left';
export type MessageProps = {
    id?: number,
    header?: string
    body?: string,
    type?: ToastType
    count?: number
}

export const ToastMessageQueue = ({ autoDeleteTime, autoDelete = false, position = 'top-right' }) => {
    const { messageQueue, DeleteToastMessage } = useContext(ToastMessageQueueContext);
    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDelete && messageQueue.length) {
                DeleteToastMessage(messageQueue[0].id);
            }
        }, autoDeleteTime);
        return () => {
            clearInterval(interval);
        }
    }, [messageQueue]);
    return (
        <ToastMessageQueueContext.Consumer>
            {({ messageQueue }) => (
                <Container className={`toast-container ${position}`}>
                    {messageQueue.map((message: MessageProps, index: number) =>
                        <Toast key={`toast-${index}`} className={`${(message.type ? 'toast-' + message.type : '')} ${position}`} fade={false}>
                            {message.header && <ToastHeader toggle={() => DeleteToastMessage(message.id)}>
                                {message.header} {message?.count > 1 && <span className="badge bg-secondary">{message.count}</span>}
                            </ToastHeader>}
                            {!!!message.header && <Button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => DeleteToastMessage(message.id)}></Button>

                            }
                            <ToastBody>
                                {!((typeof message.body) ==='object')?message.body:'' }
                            </ToastBody>
                        </Toast>
                    )
                    }
                </Container>
            )}
        </ToastMessageQueueContext.Consumer >
    );
}

import React, { useState, useEffect, FC, Dispatch } from 'react';
import PersonaRow from './PersonaRow';
import Spinner from '../Spinner';
import '../../scss/AddressCompact.scss';
import {useApi} from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { PermissionsGate}  from '../PermissionsGate';
import { HiOutlineMailOpen, HiOutlinePlus } from 'react-icons/hi';
import intl from 'react-intl-universal';
import { handleAPIError } from '../../common/errorHandler';
import { Persona } from 'axiosApi/models/persona';
import ModalPersona from './ModalPersona';
import { confirmAlert } from 'react-confirm-alert';
import { CorporateEntity } from 'axiosApi/models/corporate-entity';


interface PersonaCompactProps {
    disabled: boolean;
    personaExist: boolean
    setPersonaExist: (value: boolean) => void;
    entityId: number;
    idEntityType: number;
    editScopes?: string[];
    deleteScopes?: string[];
    allowOne?: boolean;
    inputProvider?: any; 
    setInputProvider?: (value: any) => void; 
  }

  const PersonaCompact: FC<PersonaCompactProps> = (props) => {

    const { disabled, entityId, personaExist, setPersonaExist, idEntityType ,editScopes, deleteScopes, allowOne = true, inputProvider = null, setInputProvider = null } = props;

    const api: Api = useApi();

    const [persona, setPersona] = useState<Persona>({});

	const [loading, setLoading] = useState<boolean>(false);

    const toast = useToastMessageQueue();

    const [isPersonaRowDetailOpen, setIsPersonaRowDetailOpen] = useState(false);

    const [corporateEntities, setcorporateEntities] = useState<CorporateEntity[]>([])

    const [errors, setErrors] = useState({});

    const fetchPersona = async (entityId: number, idEntityType: number) => {
        setLoading(true);
        const response = await api.PersonaApi.apiVversionPersonaidEntityGet(entityId, idEntityType, "1", {}).then((response) => {
            if(response.data) {
                setPersona({...response.data?.data});
                setPersonaExist(true)      
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
        });
        setLoading(false);
    };

    const fetchCorporateEntities = async () => {
        setLoading(true);
        const response = await api.corporateEntityApi.apiVversionCorporateEntityAllGet("1").then((response) => {
            if(response.data) {
                setcorporateEntities(response.data?.data.queryResult);
                setLoading(false);      
            };   
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
            setLoading(false);
        });
    };
    
    useEffect(() => {
        if(!personaExist){
            fetchPersona(entityId, idEntityType);
        }
    }, [personaExist]);

    useEffect(() => {
        fetchCorporateEntities();
    },[])
    
    const closePersonaRowDetail = () => setIsPersonaRowDetailOpen(false);

    const handlePersonaRowDetailOpen = () => {
        setIsPersonaRowDetailOpen(true);
    };

    const handleDelete = () => {
            confirmAlert({
                title: intl.get('delete.modal.title'),
                message: intl.get('delete.modal.personaRow.message'),
                buttons: [
                    {
                        label: intl.get('delete.modal.cancel.button'),
                        onClick: () => { }
                    },
                    {
                        label: intl.get('delete.modal.delete.button'),
                        onClick: async () => {
                            const response = await api.PersonaApi.apiVversionPersonaIdDelete(persona.id, "1", {}).then((response) => {
                                console.log('persona deleted: ',persona.id ,+ response);
                                setPersona(null);
                                setPersonaExist(false)
                                toast.success({ body: intl.get('personaRow.toast.success.handledelete')});
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);
                                setErrors({...errors});
                            });
                        },
                    }
                ]
            });
     };        

    return (
        <>
            <div className="card-header">
                <h4 className="title"><HiOutlineMailOpen className='mb-1'/> {intl.get('personaCompact.header')}</h4>
            </div>
            {
                loading === true ?
                <Spinner small={true} /> :
                <div className="card-body">
                    <div className="table-responsive container-overflow">
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th scope="col" >{intl.get('personaCompact.table.thead.name')}</th>
                                    <th scope="col" >{intl.get('personaCompact.table.thead.type')}</th>
                                    <th scope="col" >{intl.get('personaCompact.table.thead.taxId')}</th>
                                    <th scope="col" >{intl.get('personaCompact.table.thead.billTo')}</th>
                                    <th scope="col" className='text-center'>{intl.get('personaCompact.table.thead.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <PersonaRow 
                                        editScopes = {editScopes}
                                        deleteScopes= {deleteScopes}
                                        entityId = {entityId}
                                        idEntityType = {idEntityType}
                                        persona = {persona}
                                        corporateEntities = {corporateEntities}
                                        setcorporateEntities = {setcorporateEntities}
                                        setPersona = {setPersona}
                                        inputProvider = {inputProvider}
                                        setInputProvider = {setInputProvider}
                                        errors = {errors}
                                        setErrors = {setErrors}
                                        disabled = {disabled}
                                        handleDelete={handleDelete}
                                        personaExist={personaExist}
                                        setPersonaExist={setPersonaExist}
                                    />                              
                            </tbody>
                        </table>
                    </div>
                    <PermissionsGate viewScopes={editScopes} editScopes={editScopes} viewRoles={[]} editRoles={[]} >  
                        <div className="text-center mt-2">
                            <button type="submit" className="btn btn-primary newAddress" disabled={disabled || (allowOne && personaExist)} onClick={handlePersonaRowDetailOpen} ><HiOutlinePlus className='mb-1'/> {intl.get('personaCompact.newPersona.button')}</button>
                        <ModalPersona
                            entityId={entityId}
                            idEntityType={idEntityType}
                            isOpen={isPersonaRowDetailOpen} 
                            closeModal={closePersonaRowDetail} 
                            persona={persona} 
                            setPersona={setPersona} 
                            corporateEntities={corporateEntities} 
                            inputProvider={inputProvider} 
                            setInputProvider={setInputProvider}
                            setPersonaExist={setPersonaExist}
                            personaExists={personaExist}
                        />    
                        </div>
                    </PermissionsGate>
                </div>
            }
        </>
    )
}

export default PersonaCompact;
import React from 'react';
import intl from 'react-intl-universal';
import ReactLoading from 'react-loading';
import '../scss/Spinner.scss';
import { Backdrop } from '@mui/material';

interface TestProps {
    small?: boolean;
}

const Spinner = ({small = false}:TestProps) => {

    
        if(!small){
            return (
                <Backdrop
                    sx={(theme) => ({ zIndex: theme.zIndex.drawer + 99 })}
                    open={true} >
                        <div className="loading-background">
                            <div className="loading-bar" >
                                <ReactLoading type={"bars"} color={"#005BAF"} height={100} width={100} />
                            </div>
                        </div>
                </Backdrop>
            )
        }
        else
        {
            return(
            <div className='spinner'>
            <div className="loading-bar" >
            <ReactLoading type={"bars"} color={"#005BAF"} height={100} width={100} />
            </div>
            </div>)
        }
       

    
}

export default Spinner;

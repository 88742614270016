import React from 'react';
import intl from 'react-intl-universal';
import TimeOffRow from './TimeOffRow';
import { WorkflowProvider } from 'components/Workflow/WorkflowContext';

const TimeOffList = ({ reqType, searchResult, HRView, fetchRequestsHR, fetchRequestsSimpleUser }) => {
    return (
        <div className="table-responsive my-2">
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th role='button' scope="col">{intl.get('timeOff.table.thead.employee')}</th>
                        <th role='button' scope="col">{intl.get('timeOff.table.thead.status')}</th>
                        <th role='button' scope="col">{intl.get('timeOff.table.thead.request')}</th>
                        <th role='button' scope="col">{intl.get('timeOff.table.thead.date')}</th>
                        <th role='button' scope="col">{intl.get('timeOff.table.thead.actions')}</th>
                        <th role='button' scope="col" style={{ textAlign: 'center' }}>{intl.get('timeOff.table.thead.detail')}</th>
                    </tr>
                </thead>
                {
                    searchResult.queryResult === null ? 
                    <tbody className='aling-center mb-0'>
                        <tr>
                            <td colSpan={6} className=' mb-0'>{intl.get('timeOff.notFound')}</td>
                        </tr>
                    </tbody> :
                    <tbody><WorkflowProvider moduleId={2}>
                        {
                            searchResult.queryResult?.map((r,i) => (
                                <TimeOffRow 
                                    className={i%2>0?'':'odd'}
                                    key={r.id} 
                                    id={r.id} 
                                    employee= {`${r.employeeLastName} ${r.employeeName}`}
                                    status={r.workflowStateName}
                                    color={r.workflowStateColor}
                                    requestType={reqType}
                                    request={r.timeOffRequestTypeName}
                                    from={r.from}
                                    to={r.to}
                                    date={`${r.from} - ${r.to}`}
                                    idWorkflowState={r.idWorkflowState}
                                    title={r.title}
                                    idEmployee={r.idEmployee}
                                    changeLog={r.changeLog}
                                    idTimeOffRequestType={r.idTimeOffRequestType}
                                    fetchRequestsHR={fetchRequestsHR}
                                    fetchRequestsSimpleUser={fetchRequestsSimpleUser}
                                    HRView={HRView}
                                />
                            ))
                        }</WorkflowProvider>
                    </tbody>
                }
            </table>
        </div>
    )
}

export default TimeOffList;
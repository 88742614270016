import React, { useState, ChangeEventHandler, useEffect } from 'react';
import 'scss/Timesheet/TimesheetComponent.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import WeekPickerDialog from '../WeekPickerDialog';
import { Row, Col, Container, Button } from 'reactstrap';
import TimesheetTable from './TimesheetTable'
import TimesheetLogModal from './TimesheetLogModal';
import { Project, GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } from '../../axiosApi/models';
import { addDays, set } from 'date-fns';
import { previousMonday } from 'common/utils';
import { TimesheetInput, handleTimesheetSave, TimesheetEditProps, handleTimesheetDelete, convertTimesheetRowsToNewRowsWithTaskId } from './timesheetUtils';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { getUserEntity } from 'common/UserEntityProvider';
import { confirmAlert } from 'react-confirm-alert';
import { HiOutlineClock } from 'react-icons/hi';
import { PermissionsGate } from 'components/PermissionsGate';
import { SCOPES } from 'common/permissions';
import intl from 'react-intl-universal';
import Spinner from 'components/Spinner';
import { handleAPIError } from '../../common/errorHandler';

export const TimesheetComponent = () => {

  const workingWeekDays = 7;
  const defaultEmptyRows = 4;
  const userDetails = getUserEntity();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [assignedProjects, setAssignedProjects] = useState<Project[] | null>();
  const [timesheetRows, setTimesheetRows] = useState<{ [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } | undefined>();
  const [timesheetRowsOrder, setTimesheetRowsOrder] = useState<string[] | undefined>([]);

  const [timesheetEditProps, setTimesheetEditProps] = useState<TimesheetEditProps | undefined>({ date: new Date(), idEmployee: userDetails?.entityId });
  const [isTimesheetDetailOpen, setIsTimesheetDetailOpen] = useState(false);
  const [newRows, setNewRows] = useState<Array<string>>([...Array(defaultEmptyRows)].map((o, i) => 'ts_r_new_' + i));
  const [loading, setLoading] =useState(false);
  const api: Api = useApi();
  const toast = useToastMessageQueue();

  const [dateChanged, setDateChanged] = useState(false);
  const [errors, setErrors] = useState({});

  const getNumberOfRows = (timesheetsGroupedByTaskAndDate) => {
    let numberOfRows = defaultEmptyRows;
    if (timesheetsGroupedByTaskAndDate) {
      if (Object.keys(timesheetsGroupedByTaskAndDate).length > 0) {
        if (Object.keys(timesheetsGroupedByTaskAndDate).length >= defaultEmptyRows) {
          numberOfRows = 1;
        } else {
          numberOfRows = (defaultEmptyRows - Object.keys(timesheetsGroupedByTaskAndDate).length);
        }
      }
      // else {
      //   numberOfRows = newRows.length;
      // }
    }
    return numberOfRows;
  }


  const fetchTimesheets = async (idEmployee: number, date = new Date()) => {
    if (idEmployee) {
      setLoading(true);
      api.timesheetApi.apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee, date, addDays(date, workingWeekDays - 1), "1", {}).then((response) => {
        if (response.data) {
          setTimesheetRows(response.data.data?.timesheetsGroupedByTaskAndDate);
          setTimesheetRowsOrder(Object.keys(response.data.data?.timesheetsGroupedByTaskAndDate));
          let numberOfRows = getNumberOfRows(response.data.data?.timesheetsGroupedByTaskAndDate);
          console.log('fetchTimesheets', { numberOfRows, timesheetRows: response.data.data?.timesheetsGroupedByTaskAndDate });
          setNewRows(numberOfRows > 0 ? [...Array(numberOfRows)].map((o, i) => 'ts_r_new_' + i) : []);
          
        };
        setLoading(false);
      }).catch((error)=>{
        handleAPIError(error, toast, errors);
        setErrors({...errors});
        setLoading(false);
      });
      
    }
  };

  const fetchAssignedProjects = async (idEmployee: number, date = new Date()) => {
    if (idEmployee) {
      setLoading(true);
      const endDate = new Date(date);
      endDate.setUTCDate(endDate.getUTCDate() - 1);
      const endDateYear = endDate.getUTCFullYear();
      const endDateMonth = String(endDate.getUTCMonth() + 1).padStart(2, '0');
      const endDateDay = String(endDate.getUTCDate()).padStart(2, '0');
      const formattedEndDate = `${endDateYear}-${endDateMonth}-${endDateDay}`;
      api.projectApi.apiVversionProjectGetByEmployeeIdIdGet(idEmployee, "1", formattedEndDate, { headers: { 'Access-Control-Allow-Origin': '*' } }).then((response) => {
        if (response.data) {
          setAssignedProjects(response.data.data?.queryResult);
        };
      }).catch((error)=>{
        handleAPIError(error, toast, errors);
        setErrors({...errors});
      }).finally(() => {
        setLoading(false);
      });
    }
  };



  const handleDateChange = (date: Date) => {
    if (selectedDate != date) {
      setSelectedDate(date)
      setDateChanged(true);
      fetchTimesheets( userDetails.entityId , date);
      console.log('handleDateChange', { date, userDetails, selectedDate });
      fetchAssignedProjects(userDetails?.entityId, date);
    }
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {

  };



  const editTimesheet = ({ date, idTimesheet, idProject, idRow, task, duration, description }: TimesheetEditProps) => {
    console.log('editTimesheet', { date, idTimesheet, idProject, idRow, task, duration, description });
    setTimesheetEditProps({ ...timesheetEditProps, date: date, idTimesheet: idTimesheet, idProject: idProject, idRow: idRow, task: task, duration: duration, description: description });
    setIsTimesheetDetailOpen(true);
  }

  const closeEditTimesheet = () => setIsTimesheetDetailOpen(false);


  const insertTimesheetinRows = (initial: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse }, inserted: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse }): { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } => {
    Object.keys(inserted).forEach((idTask, index) => {
      const date = Object.keys(inserted[idTask].timesheetsGroupedByDate)[0];

      if (initial[idTask] != null) {
        if (inserted[idTask].timesheetsGroupedByDate && date)
          initial[idTask].timesheetsGroupedByDate[date] = inserted[idTask].timesheetsGroupedByDate[date];
      }
      else
        initial[idTask] = inserted[idTask];
    });
    return { ...initial };
  }
  const insertPrevWeekInRows = (initial: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse }, inserted: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse }): { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } => {
    const initialTasks = Object.keys(initial);
    Object.keys(inserted).forEach((idTask, index) => {
      if (!initialTasks.includes(idTask)) {
        initial[idTask] = inserted[idTask];
      }
    });
    return { ...initial };
  }


  const removeTimesheetFromRows = (initial: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse }, remove: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse }): { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } => {
    if (Object.keys(remove).length) {
      const idTask = Object.keys(remove)[0];
      const date = Object.keys(remove[idTask].timesheetsGroupedByDate)[0];
      if (initial[idTask] != undefined) {
        delete initial[idTask].timesheetsGroupedByDate[date];
      }
      if (initial[idTask] != undefined && Object.keys(initial[idTask].timesheetsGroupedByDate).length == 0) {
        delete initial[idTask];
      }
    }
    return { ...initial };
  }

  const handleTimesheetSaveAndUpdate = (api: Api, input: TimesheetInput, closeModal: Function, idRow: string = null, removeDeleted = {}) => {
    setLoading(true);
    handleTimesheetSave(api, input, closeModal, toast, errors, setErrors).then((timesheetResponse) => {
      if(!!timesheetResponse){//Check for null that can be returned by handleTimesheetSave when an error or validation error ocurred
        const tr = insertTimesheetinRows(removeTimesheetFromRows(timesheetRows, removeDeleted), timesheetResponse);
        setTimesheetRowsOrder(prev => [...prev, ...Object.keys(timesheetResponse).filter((k => !prev.includes(k)))]);
        setTimesheetRows({ ...tr });
        if (idRow != null) {
          console.log('handleTimesheetSaveAndUpdate', { idRow, newRows });
          setNewRows(newRows.filter((id) => id != idRow));
        }
        setLoading(false);
      }
      else{
        setTimesheetRows({ ...timesheetRows });
        setLoading(false);
      }
    }).catch((error) => {
      handleAPIError(error, toast, errors);
      setErrors({...errors});
      setLoading(false);
    });
  }
  const timesheetDelete = (idTimesheet: number, removeDeleted = {}) => {
    if (idTimesheet != null) {
      setLoading(true);
      handleTimesheetDelete(api, idTimesheet, toast, errors, setErrors).then((timesheetResponse) => {
        if (timesheetResponse) {
          setTimesheetRows({ ...removeTimesheetFromRows(timesheetRows, removeDeleted) });
        }
      }).finally(() => {
        setLoading(false);
      });
    } else if (Object.keys(removeDeleted).length > 0) {
      setTimesheetRows({ ...removeTimesheetFromRows(timesheetRows, removeDeleted) });
    }
  }

  const addTimesheetRow = () => {
    console.log('addTimesheetRow', { newRows });
    setNewRows([...newRows, 'ts_r_new_' + newRows.length]);
  }

  const deleteRow = (id) => {
    if (!isNaN(id)) {
      if (timesheetRows[id] && Object.keys(timesheetRows[id].timesheetsGroupedByDate).length > 0) {
        confirmAlert({
          title: intl.get('delete.modal.title'),
          message: 'All timesheet logs on this row will be deleted. Are you sure you want to perform this action?',
          buttons: [
            {
              label: 'Cancel',
              onClick: () => { }
            },
            {
              label: 'Accept',
              onClick: () => {
                Object.keys(timesheetRows[id].timesheetsGroupedByDate).forEach((tsk) => {
                  timesheetDelete(timesheetRows[id].timesheetsGroupedByDate[tsk].id, { [id]: { timesheetsGroupedByDate: { [tsk]: {} } } });
                });
                setTimesheetRowsOrder(prev => [...prev.filter(i => i != id)]);
                //delete timesheetRows[id];

              },
            }
          ]
        });
      } else {
        timesheetDelete(null, { [id]: { timesheetsGroupedByDate: { [id]: {} } } });
      }
    } else {
      setNewRows(newRows.filter((rowId) => rowId != id));
      //setTimesheetRowsOrder(prev => [...prev.filter(i => i != id)]);
      timesheetDelete(null);
    }

  }

  const handleCopyPreviousWeek = () => {
    if (userDetails.entityId) {
      setLoading(true);
      const prevMonday = addDays(selectedDate, -7);
      api.timesheetApi.apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(userDetails.entityId, prevMonday, addDays(prevMonday, workingWeekDays - 1), "1", {}).then((response) => {
        if (response.data) {
          const rows = convertTimesheetRowsToNewRowsWithTaskId(response.data.data?.timesheetsGroupedByTaskAndDate, selectedDate);
          if (Object.keys(rows).length === 0 || JSON.stringify(Object.keys(rows).sort()) === JSON.stringify(Object.keys(timesheetRows).sort())) {
            toast.info({ body: intl.get('timesheetComponent.toast.info.handleCopyPreviousWeek')});
          } else {
            const prevCount = Object.keys(timesheetRows).length;
            const tr = insertPrevWeekInRows(removeTimesheetFromRows(timesheetRows, {}), rows);
            if (Object.keys(tr).length > prevCount) {
              setTimesheetRowsOrder(prev => [...prev, ...Object.keys(tr).filter(k => !prev.includes(k))]);
              setTimesheetRows({ ...tr });
              let numberOfRows = getNumberOfRows(response.data.data?.timesheetsGroupedByTaskAndDate);
              setNewRows(numberOfRows > 0 ? [...Array(numberOfRows)].map((o, i) => 'ts_r_new_' + i) : []);
            }
            else {
              toast.info({ body: intl.get('timesheetComponent.toast.info.handleCopyPreviousWeek')});
            }
          }
        };
        setLoading(false);
      }).catch((error)=>{
        handleAPIError(error, toast, errors);
        setErrors({...errors});
        setLoading(false);
      });
    }
  }
  const deleteAllWeek = () => {
    Object.keys(timesheetRows).forEach((idTask) => {
      Object.keys(timesheetRows[idTask].timesheetsGroupedByDate).forEach((tsk) => {
        handleTimesheetDelete(api, timesheetRows[idTask].timesheetsGroupedByDate[tsk].id, toast, errors, setErrors);
      });
    })
    setTimesheetRows({});
  }

  useEffect(() => {
    if(newRows.length === 0 && getNumberOfRows(timesheetRows) === 0){
      setNewRows([...Array(defaultEmptyRows[0])].map((o, i) => 'ts_r_new_' + i));
    }else if(newRows.length === 0 && Object.keys(timesheetRows).length > 0){
      let numberOfRows = getNumberOfRows(timesheetRows);
      setNewRows(numberOfRows > 0 ? [...Array(numberOfRows)].map((o, i) => 'ts_r_new_' + i) : []);
    }
  }, [timesheetRows, newRows]);

  useEffect(() => {
    if (!!!timesheetRows)
      fetchTimesheets(userDetails?.entityId, selectedDate);
    if (!!!assignedProjects)
      fetchAssignedProjects(userDetails?.entityId, selectedDate);
      

  }, [userDetails?.entityId, selectedDate]);

  return (

    <Container >
      <div className='card mt-4 timesheet'>
      {
        loading === true && <Spinner /> 
      }
        <PermissionsGate viewScopes={[SCOPES['timesheet.read']]} editScopes={[SCOPES['timesheet.edit']]} viewRoles={[]} editRoles={[]} RenderError={() => (<span>{intl.get('permissionsGate')}</span>)} >
          <div className="card-header">
            <h2 className="title"><HiOutlineClock className='mb-1' /> {intl.get('timesheetComponent.header')}</h2>
          </div>
          <div className='card-body'>
            <Row>
              <Col md={12}>
                <WeekPickerDialog workingWeekDays={workingWeekDays} selectedDate={selectedDate} handleDateChange={handleDateChange} handleInputChange={handleInputChange}></WeekPickerDialog>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <hr />
              </Col>
            </Row>
            <Row className="d-grid gap-2 d-md-flex justify-content-md-around">
              <Col md={12}>
                <TimesheetTable api={api} idEmployee={userDetails?.entityId} workingWeekDays={workingWeekDays} date={previousMonday(selectedDate)} projects={assignedProjects} timesheetRows={timesheetRows} durationClick={editTimesheet} handleTimesheetSave={handleTimesheetSaveAndUpdate} newRows={newRows} deleteRow={deleteRow} timesheetRowsOrder={timesheetRowsOrder} loading={loading} setLoading={setLoading} dateChanged={dateChanged} setDateChanged={setDateChanged}></TimesheetTable>
                {isTimesheetDetailOpen && assignedProjects && timesheetEditProps.idEmployee && timesheetEditProps.date && timesheetEditProps.task && <TimesheetLogModal projects={assignedProjects} isOpen={isTimesheetDetailOpen} closeModal={closeEditTimesheet} timesheetEditProps={timesheetEditProps} handleTimesheetSave={handleTimesheetSaveAndUpdate} loading={loading} timesheetRows={timesheetRows} setTimesheetRows={setTimesheetRows} errors={errors} setErrors={setErrors} />}
              </Col>
            </Row>
            <Row className="d-grid gap-2 d-flex justify-content-around mt-2">
              <Col size={12} className="d-grid gap-4 d-flex justify-content-start">
                <Button className="btn btn-primary" onClick={() => addTimesheetRow()} >{intl.get('timesheetComponent.add.button')}</Button>
                <Button className="btn btn-primary" onClick={() => handleCopyPreviousWeek()} >{intl.get('timesheetComponent.copy.button')}</Button>
              </Col>
            </Row>
          </div>
        </PermissionsGate>
      </div>
    </Container>
  )
}


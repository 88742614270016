import React, { useEffect, FC } from 'react';
import {SignOutButton} from './SignOutButton';
import { PermissionsGate}  from './PermissionsGate';
import { SCOPES } from 'common/permissions';
import { HiOutlineCash, HiOutlineClock, HiOutlineUser, HiOutlineCog, HiOutlineBell, HiOutlineTicket, HiOutlineUserAdd, HiIdentification, HiOutlineClipboardList, HiOutlineCalendar, HiGlobeAlt } from 'react-icons/hi';
import intl from 'react-intl-universal';
import {Link} from "react-router";
import '../scss/MainNavigation.scss';
import { ENTITY_TYPE } from 'common/constants';
import SwitchProfile from '../components/SwitchProfile';
import { set } from 'date-fns';

type MainNavigationPropType = {
    isAuthenticated:boolean|false;
};

export const MainNavigation: FC<MainNavigationPropType> = (parameters) => {

    const [open, setOpen] = React.useState(false);

    const [openReportsMenu, setOpenReportsMenu] = React.useState(false);
    
    const [openTimeOffMenu, setOpenTimeOffMenu] = React.useState(false);

    const [openUserMenu, setOpenUserMenu] = React.useState(false);

    const [openLanguageMenu, setOpenLanguageMenu] = React.useState(false);

    const [openSettingMenu, setOpenSettingMenu] = React.useState(false);

    const [openProfileMenu, setOpenProfileMenu] = React.useState(true);

    const [openInvoicesMenu, setOpenInvoicesMenu] = React.useState(false);

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const handleCloseMenu = () => {
        setOpen(false);
        setOpenReportsMenu(false);
        setOpenUserMenu(false);
        setOpenSettingMenu(false);
        setOpenTimeOffMenu(false);
        setOpenInvoicesMenu(false)
    };

    const handleCloseMenus = () => {
        setOpenUserMenu(false);
        setOpenSettingMenu(false);
        setOpenReportsMenu(false);
        setOpenLanguageMenu(false);
        setOpenTimeOffMenu(false);
        setOpenInvoicesMenu(false);
      };

    const handleDocumentClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const isMenuButton =
            target.closest('#userMenuDropdown') ||
            target.closest('#settingMenuDropdown') ||
            target.closest('#navbarDropdown') ||
            target.closest('#languageMenu') ||
            target.closest('#switchProfile') ||
            target.id && target.id.includes('react-select-');
        if (isMenuButton) {
            return;
        }
        handleCloseMenus();
    };

    const handleReportsMenu = () => {
        setOpenReportsMenu(!openReportsMenu);
        setOpenTimeOffMenu(false);
        setOpenSettingMenu(false);
        setOpenUserMenu(false);
        setOpenLanguageMenu(false);
        setOpenInvoicesMenu(false);
    };

    const handleTimeOffMenu = () => {
        setOpenTimeOffMenu(!openTimeOffMenu);
        setOpenReportsMenu(false);
        setOpenSettingMenu(false);
        setOpenUserMenu(false);
        setOpenLanguageMenu(false);
        setOpenInvoicesMenu(false);
    };

    const handleUserMenu = () => {
        setOpenUserMenu(!openUserMenu);
        setOpenSettingMenu(false);
        setOpenReportsMenu(false);
        setOpenLanguageMenu(false);
        setOpenTimeOffMenu(false);
        setOpenInvoicesMenu(false);
    };

    const handleSettingMenu = () => {
        setOpenSettingMenu(!openSettingMenu);
        setOpenUserMenu(false);
        setOpenReportsMenu(false);
        setOpenLanguageMenu(false);
        setOpenTimeOffMenu(false);
        setOpenInvoicesMenu(false);
    };

    const handleLanguageMenu = () => {
        if(openReportsMenu === false && openSettingMenu === false && openTimeOffMenu === false) {
            setOpenLanguageMenu(!openLanguageMenu);
        };
    };

    const handleInvoicesMenu = () => {
        setOpenInvoicesMenu(!openInvoicesMenu);
        setOpenTimeOffMenu(false);
        setOpenReportsMenu(false);
        setOpenSettingMenu(false);
        setOpenUserMenu(false);
        setOpenLanguageMenu(false);
    };

    const ReportsMenu = () => {

        return( 
            <> 
                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" onClick={handleReportsMenu} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {intl.get('mainNavigation.reports')}
                </Link>
                <div className={open ? "dropdown-menu dark" : "dropdown-menu"} aria-labelledby="navbarDropdown" style={openReportsMenu?{"display":"block"}:{"display":"none"}} >
                    <PermissionsGate viewScopes={[SCOPES['reports.timesheet.read']]} editScopes={[SCOPES['reports.timesheet.read']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >
                        <Link className="dropdown-item" to="/reports/timesheet" onClick={handleCloseMenu}><HiOutlineClock className='mb-1'/> {intl.get('mainNavigation.timesheet')}</Link>
                    </PermissionsGate>
                    <PermissionsGate viewScopes={[SCOPES['reports.timesheet.read']]} editScopes={[SCOPES['reports.timesheet.read']]} entityType={ENTITY_TYPE.CLIENT}  viewRoles={[]} editRoles={[]} >  
                        <Link className="dropdown-item" to="/reports/timesheet/client" onClick={handleCloseMenu}><HiOutlineClock className='mb-1'/> {intl.get('mainNavigation.timesheet')}</Link>
                    </PermissionsGate>
                    <PermissionsGate viewScopes={[SCOPES['reports.billing.read']]} editScopes={[SCOPES['reports.billing.read']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >  
                        <Link className="dropdown-item" to="/reports/billing" onClick={handleCloseMenu}><HiOutlineCash className='mb-1'/> {intl.get('mainNavigation.billing')}</Link>
                    </PermissionsGate>
                    <PermissionsGate viewScopes={[SCOPES['reports.billing.read']]} editScopes={[SCOPES['reports.billing.read']]} entityType={ENTITY_TYPE.CLIENT} viewRoles={[]} editRoles={[]} >
                        <Link className="dropdown-item" to="/reports/billing/client" onClick={handleCloseMenu}><HiOutlineCash className='mb-1'/> {intl.get('mainNavigation.billing')}</Link>
                    </PermissionsGate>
                </div>
            </>
        )
    };

    const HumanResources = () => {
        
        return( 
            <> 
                <PermissionsGate viewScopes={[SCOPES['pto.read'], SCOPES['pto.add'], SCOPES['pto.edit'],SCOPES['nonworkingday.read'], SCOPES['nonworkingday.add'], SCOPES['nonworkingday.edit']]} editScopes={[SCOPES['pto.read'], SCOPES['pto.add'], SCOPES['pto.edit'],SCOPES['nonworkingday.read'], SCOPES['nonworkingday.add'], SCOPES['nonworkingday.edit']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >
                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" onClick={handleTimeOffMenu} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {intl.get('mainNavigation.humanResources')}
                    </Link>
                </PermissionsGate>
                <div className={open ? "dropdown-menu dark" : "dropdown-menu"} aria-labelledby="navbarDropdown" style={openTimeOffMenu?{"display":"block"}:{"display":"none"}} >
                    <PermissionsGate viewScopes={[SCOPES['pto.read'], SCOPES['pto.add'], SCOPES['pto.edit']]} editScopes={[SCOPES['pto.read'], SCOPES['pto.add'], SCOPES['pto.edit']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >
                        <Link className="dropdown-item" to="/timeOff" onClick={handleCloseMenu}><HiOutlineClipboardList className='mb-1'/> {intl.get('mainNavigation.myPTO')}</Link>
                    </PermissionsGate>
                    <PermissionsGate viewScopes={[SCOPES['timeoff.rrhh']]} editScopes={[SCOPES['timeoff.rrhh']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >
                        <Link className="dropdown-item" to="/timeOffHR" onClick={handleCloseMenu}><HiOutlineClipboardList className='mb-1'/> {intl.get('mainNavigation.manage.PTO')}</Link>
                    </PermissionsGate>
                    <PermissionsGate viewScopes={[SCOPES['nonworkingday.read'], SCOPES['nonworkingday.add'], SCOPES['nonworkingday.edit']]} editScopes={[SCOPES['nonworkingday.read'], SCOPES['nonworkingday.add'], SCOPES['nonworkingday.edit']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >
                        <Link className="dropdown-item" to="/Calendar/Holidays" onClick={handleCloseMenu}><HiOutlineCalendar className='mb-1'/> {intl.get('mainNavigation.holidays')}</Link>
                    </PermissionsGate>
                </div>
            </>
        )
    };

    const LanguageMenu = () => {

        const handleChangeLanguage = (lang) => {
            localStorage.language = lang;
            setOpenLanguageMenu(!openLanguageMenu);
            location.reload();
        };

        return( 
            <> 
                <Link className="dropdown-toggle user-options" to="#" id="languageMenu" onClick={handleLanguageMenu} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <HiGlobeAlt className="me-1 mb-1"/>
                    {intl.get('mainNavigation.languageMenu')}
                </Link>
                <div className={open ? "dropdown-menu dark language-menu" : "dropdown-menu language-menu"} aria-labelledby="languageMenu" style={openLanguageMenu?{"display":"block"}:{"display":"none"}} >
                    <Link className="dropdown-item" to="#" onClick={() => handleChangeLanguage('en')} >{intl.get('mainNavigation.languageMenu.english')}</Link>
                    <Link className="dropdown-item" to="#" onClick={() => handleChangeLanguage('es')} >{intl.get('mainNavigation.languageMenu.spanish')}</Link>
                </div>
            </>
        )
    };

    const UserMenu = () => {

        return( 
            <div className="dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="userMenuDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleUserMenu}>
                    <HiOutlineUser className='icons'/>
                </a>
                <ul className={open ? "dropdown-menu dark custom-dropdown-menu" : "dropdown-menu custom-dropdown-menu"} aria-labelledby="userMenuDropdown" style={openUserMenu?{"display":"block"}:{"display":"none"}}>
                    <li className={openProfileMenu ? "dropdown-item hover-language" :"dropdown-item"}>
                        <SwitchProfile openProfileMenu={openProfileMenu} />
                    </li>
                    <li className={openLanguageMenu ? "dropdown-item hover-language" :"dropdown-item"}>
                        <LanguageMenu/>
                    </li>
                    <li className="dropdown-item">
                        <SignOutButton/>
                    </li>
                </ul>
            </div>
        )
    };

    const Setting = () => {

        return(
            <div className="dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="settingMenuDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleSettingMenu}>
                    <HiOutlineCog className='icons'/>
                </a>
                <div className={open ? "dropdown-menu dark custom-dropdown-menu" : "dropdown-menu custom-dropdown-menu"} aria-labelledby="settingMenuDropdown" style={openSettingMenu?{"display":"block"}:{"display":"none"}}>
                    <PermissionsGate viewScopes={[SCOPES['users.read']]} editScopes={[SCOPES['users.read']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >
                        <Link className="dropdown-item" to="/users" onClick={handleCloseMenu}><HiOutlineUserAdd className='mb-1'/> {intl.get('mainNavigation.setting.users')}</Link>
                    </PermissionsGate>
                    <PermissionsGate viewScopes={[SCOPES['users.read']]} editScopes={[SCOPES['users.read']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >  
                        <Link className="dropdown-item" to="/roles" onClick={handleCloseMenu}><HiIdentification className='mb-1'/> {intl.get('mainNavigation.setting.roles')}</Link>
                    </PermissionsGate>
                    <PermissionsGate viewScopes={[SCOPES['workflows.read']]} editScopes={[SCOPES['workflows.edit']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >  
                        <Link className="dropdown-item" to="/workflows" onClick={handleCloseMenu}><HiIdentification className='mb-1'/> {intl.get('mainNavigation.setting.workflows')}</Link>
                    </PermissionsGate>
                    <Link className="dropdown-item" to="/corporatesEntities" onClick={handleCloseMenu}><HiIdentification className='mb-1'/> Manage Corporate Entities</Link>
                </div>
            </div>
        )
    };

    const Invoices = () => {
        
        return( 
            <> 
                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" onClick={handleInvoicesMenu} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Invoices
                </Link>
                <div className={open ? "dropdown-menu dark" : "dropdown-menu"} aria-labelledby="navbarDropdown" style={openInvoicesMenu?{"display":"block"}:{"display":"none"}} >
                    <Link className="dropdown-item" to="/myInvoices" onClick={handleCloseMenu}>My Invoices</Link>
                    <Link className="dropdown-item" to="/invoices" onClick={handleCloseMenu}>Invoices</Link>
                </div>
            </>
        )
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
            <img className="me-1" src={"/smalllogo.png"} height="30px"/>
                <span>
                    <img className="me-1 " style={{"verticalAlign":"text-bottom"}} src={"https://www.borderless-software.com/img/B-logo.png"} height="30px"/>
                    <Link className="navbar-brand me-2 erp" to="/">{intl.get('mainNavigation.intranet')}</Link>
                </span>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={()=>{setOpen(!open)}}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`${open ? 'navbar-collapse' : 'collapse navbar-collapse'}`} id="navbarSupportedContent">
                    <div className="col-md-8">
                        { 
                            parameters.isAuthenticated && 
                            (<ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/" onClick={handleCloseMenu} >{intl.get('mainNavigation.home')}</Link>
                                </li>
                                <PermissionsGate viewScopes={[SCOPES['clients.read']]} editScopes={[SCOPES['clients.read']]} viewRoles={[]} editRoles={[]} >  
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/clients" onClick={handleCloseMenu}>{intl.get('mainNavigation.clients')}</Link>
                                    </li>
                                </PermissionsGate>
                                <PermissionsGate viewScopes={[SCOPES['providers.read']]} editScopes={[SCOPES['providers.read']]} viewRoles={[]} editRoles={[]} >  
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/providers" onClick={handleCloseMenu}>{intl.get('mainNavigation.providers')}</Link>
                                    </li>
                                </PermissionsGate>
                                <PermissionsGate viewScopes={[SCOPES['projects.read']]} editScopes={[SCOPES['projects.read']]} viewRoles={[]} editRoles={[]} >  
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/projects" onClick={handleCloseMenu}>{intl.get('mainNavigation.projects')}</Link>
                                    </li>
                                </PermissionsGate>
                                <PermissionsGate viewScopes={[SCOPES['timesheet.read']]} editScopes={[SCOPES['timesheet.read']]} viewRoles={[]} editRoles={[]} >  
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/timesheet" onClick={handleCloseMenu}>{intl.get('mainNavigation.timesheet')}</Link>
                                    </li>
                                </PermissionsGate>
                                <PermissionsGate viewScopes={[SCOPES['employees.read']]} editScopes={[SCOPES['employees.read']]} viewRoles={[]} editRoles={[]} >  
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/employees" onClick={handleCloseMenu}>{intl.get('mainNavigation.employees')}</Link>
                                    </li> 
                                </PermissionsGate>  
                                <PermissionsGate viewScopes={[SCOPES['reports.read']]} editScopes={[SCOPES['reports.read']]} entityType={ENTITY_TYPE.ANY} viewRoles={[]} editRoles={[]} >  
                                    <li className="nav-item dropdown">
                                        <ReportsMenu></ReportsMenu>
                                    </li>
                                </PermissionsGate>
                                <PermissionsGate viewScopes={[SCOPES['pto.read'], SCOPES['nonworkingday.read']]} editScopes={[SCOPES['pto.read'], SCOPES['nonworkingday.read']]} entityType={ENTITY_TYPE.ANY} viewRoles={[]} editRoles={[]} >  
                                    <li className="nav-item dropdown">
                                        <HumanResources></HumanResources>
                                    </li>
                                </PermissionsGate>
                                    <li className="nav-item dropdown">
                                        <Invoices></Invoices>
                                    </li>
                            </ul>)
                        }
                    </div>
                    <div className='col-md-4 d-flex justify-content-lg-end align-items-center'>
                    { 
                        parameters.isAuthenticated &&
                        <ul className='navbar-nav mr-auto align-items-lg-center'>
                            <UserMenu/>
                            <PermissionsGate viewScopes={[SCOPES['users.read']]} editScopes={[SCOPES['users.read']]} viewRoles={[]} editRoles={[]} >
                                <Setting/>
                            </PermissionsGate>  
                            <div className="dropdown">
                                <a className='nav-link' href='#'>
                                    <HiOutlineBell className='icons'/>
                                </a>
                            </div>
                            <div className="dropdown">
                                <a className='nav-link' href='#'>
                                    <HiOutlineTicket className='icons'/>
                                </a>
                            </div>
                        </ul>
                    }
                    </div>
                </div>
            </div>
        </nav>
    )
}
/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Employee API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

 import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
 import { Configuration } from '../configuration';
 // Some imports not used depending on template conditions
 // @ts-ignore
 import { Settings }  from "../../common/settings";
 import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
 const BASE_PATH = Settings.getInstance().BASE_API_URL_EMPLOYEE;
 import { CreateUserCommand, ListOptionDTOSearchResponseResponse } from '../models';
 import { DisplayNameDTOSearchResponseResponse } from '../models';
 import { StatusCodeResult } from '../models';
 import { StringResponse } from '../models';
 import { UpdateUserCommand } from '../models';
 import { UpdateUserSecurityRolesCommand } from '../models/update-user-security-roles-command';
 import { UserByIdResponseResponse } from '../models';
 
 import { UserEntityDetailsSearchResponseResponse } from '../models/user-entity-details-search-response-response';
 import { UserListResponse } from '../models';
 import { UserResponse } from '../models';
 import { UserSearchResponseResponse } from '../models';
 /**
  * UserApi - axios parameter creator
  * @export
  */
 export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Gets the list of all User
          * @param {string} version 
          * @param {number} [pageNum] 
          * @param {number} [pageSize] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         apiVversionUserAllGet: async (version: string, pageNum?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserAllGet.');
             }
             const localVarPath = `/api/v{version}/User/all`
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, 'https://example.com');
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             // authentication Bearer required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Authorization")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
             }
 
             // authentication Userentity required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Userentity")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
             }
 
             if (pageNum !== undefined) {
                 localVarQueryParameter['pageNum'] = pageNum;
             }
 
             if (pageSize !== undefined) {
                 localVarQueryParameter['pageSize'] = pageSize;
             }
 
             const query = new URLSearchParams(localVarUrlObj.search);
             for (const key in localVarQueryParameter) {
                 query.set(key, localVarQueryParameter[key]);
             }
             for (const key in options.params) {
                 query.set(key, options.params[key]);
             }
             localVarUrlObj.search = (new URLSearchParams(query)).toString();
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Gets a User by emailAddress
          * @param {string} emailAddress 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         apiVversionUserEmailAddressEmailAddressGet: async (emailAddress: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'emailAddress' is not null or undefined
             if (emailAddress === null || emailAddress === undefined) {
                 throw new RequiredError('emailAddress','Required parameter emailAddress was null or undefined when calling apiVversionUserEmailAddressEmailAddressGet.');
             }
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserEmailAddressEmailAddressGet.');
             }
             const localVarPath = `/api/v{version}/User/emailAddress/{emailAddress}`
                 .replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)))
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, 'https://example.com');
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             // authentication Bearer required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Authorization")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
             }
 
             // authentication Userentity required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Userentity")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
             }
 
             const query = new URLSearchParams(localVarUrlObj.search);
             for (const key in localVarQueryParameter) {
                 query.set(key, localVarQueryParameter[key]);
             }
             for (const key in options.params) {
                 query.set(key, options.params[key]);
             }
             localVarUrlObj.search = (new URLSearchParams(query)).toString();
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Deletes a User by id
          * @param {number} id 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         apiVversionUserIdDelete: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionUserIdDelete.');
             }
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserIdDelete.');
             }
             const localVarPath = `/api/v{version}/User/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, 'https://example.com');
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             // authentication Bearer required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Authorization")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
             }
 
             // authentication Userentity required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Userentity")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
             }
 
             const query = new URLSearchParams(localVarUrlObj.search);
             for (const key in localVarQueryParameter) {
                 query.set(key, localVarQueryParameter[key]);
             }
             for (const key in options.params) {
                 query.set(key, options.params[key]);
             }
             localVarUrlObj.search = (new URLSearchParams(query)).toString();
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Gets an User by id
          * @param {number} id 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         apiVversionUserIdGet: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionUserIdGet.');
             }
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserIdGet.');
             }
             const localVarPath = `/api/v{version}/User/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, 'https://example.com');
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             // authentication Bearer required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Authorization")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
             }
 
             // authentication Userentity required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Userentity")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
             }
 
             const query = new URLSearchParams(localVarUrlObj.search);
             for (const key in localVarQueryParameter) {
                 query.set(key, localVarQueryParameter[key]);
             }
             for (const key in options.params) {
                 query.set(key, options.params[key]);
             }
             localVarUrlObj.search = (new URLSearchParams(query)).toString();
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Updates the assigned security roles for a specific user. Payload requieres the UserId and the list of roles to be assigned.
          * @param {number} id 
          * @param {string} version 
          * @param {UpdateUserSecurityRolesCommand} [body] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         apiVversionUserIdSecurityrolesPut: async (id: number, version: string, body?: UpdateUserSecurityRolesCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionUserIdSecurityrolesPut.');
             }
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserIdSecurityrolesPut.');
             }
             const localVarPath = `/api/v{version}/User/{id}/securityroles`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, 'https://example.com');
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             // authentication Bearer required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Authorization")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
             }
 
             // authentication Userentity required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Userentity")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
             }
 
             localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
 
             const query = new URLSearchParams(localVarUrlObj.search);
             for (const key in localVarQueryParameter) {
                 query.set(key, localVarQueryParameter[key]);
             }
             for (const key in options.params) {
                 query.set(key, options.params[key]);
             }
             localVarUrlObj.search = (new URLSearchParams(query)).toString();
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
 
             return {
                 url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Creates a New User.
          * @param {string} version 
          * @param {CreateUserCommand} [body] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         apiVversionUserPost: async (version: string, body?: CreateUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserPost.');
             }
             const localVarPath = `/api/v{version}/User`
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, 'https://example.com');
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             // authentication Bearer required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Authorization")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
             }
 
             // authentication Userentity required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Userentity")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
             }
 
             localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
 
             const query = new URLSearchParams(localVarUrlObj.search);
             for (const key in localVarQueryParameter) {
                 query.set(key, localVarQueryParameter[key]);
             }
             for (const key in options.params) {
                 query.set(key, options.params[key]);
             }
             localVarUrlObj.search = (new URLSearchParams(query)).toString();
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
 
             return {
                 url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Updates an existing User
          * @param {string} version 
          * @param {UpdateUserCommand} [body] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         apiVversionUserPut: async (version: string, body?: UpdateUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserPut.');
             }
             const localVarPath = `/api/v{version}/User`
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, 'https://example.com');
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             // authentication Bearer required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Authorization")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
             }
 
             // authentication Userentity required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Userentity")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
             }
 
             localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
 
             const query = new URLSearchParams(localVarUrlObj.search);
             for (const key in localVarQueryParameter) {
                 query.set(key, localVarQueryParameter[key]);
             }
             for (const key in options.params) {
                 query.set(key, options.params[key]);
             }
             localVarUrlObj.search = (new URLSearchParams(query)).toString();
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
 
             return {
                 url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Searchs a User
          * @param {string} version 
          * @param {string} [term] 
          * @param {number} [pageNum] 
          * @param {number} [pageSize] 
          * @param {string} [sort] 
          * @param {string} [order] 
          * @param {Array<string>} [f] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         apiVversionUserSearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserSearchGet.');
             }
             const localVarPath = `/api/v{version}/User/search`
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, 'https://example.com');
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             // authentication Bearer required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Authorization")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
             }
 
             // authentication Userentity required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Userentity")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
             }
 
             if (term !== undefined) {
                 localVarQueryParameter['term'] = term;
             }
 
             if (pageNum !== undefined) {
                 localVarQueryParameter['pageNum'] = pageNum;
             }
 
             if (pageSize !== undefined) {
                 localVarQueryParameter['pageSize'] = pageSize;
             }
 
             if (sort !== undefined) {
                 localVarQueryParameter['sort'] = sort;
             }
 
             if (order !== undefined) {
                 localVarQueryParameter['order'] = order;
             }
 
             const query = new URLSearchParams(localVarUrlObj.search);
 
             if (f) {
                f.forEach(e => {
                    query.append("f", e.toString())
                });
             }
             for (const key in localVarQueryParameter) {
                 query.set(key, localVarQueryParameter[key]);
             }
             for (const key in options.params) {
                 query.set(key, options.params[key]);
             }
             localVarUrlObj.search = (new URLSearchParams(query)).toString();
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Gets all Users by typeahead
          * @param {string} text 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         apiVversionUserTypeaheadTextGet: async (text: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'text' is not null or undefined
             if (text === null || text === undefined) {
                 throw new RequiredError('text','Required parameter text was null or undefined when calling apiVversionUserTypeaheadTextGet.');
             }
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserTypeaheadTextGet.');
             }
             const localVarPath = `/api/v{version}/User/typeahead/{text}`
                 .replace(`{${"text"}}`, encodeURIComponent(String(text)))
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, 'https://example.com');
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             // authentication Bearer required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Authorization")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
             }
 
             // authentication Userentity required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Userentity")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
             }
 
             const query = new URLSearchParams(localVarUrlObj.search);
             for (const key in localVarQueryParameter) {
                 query.set(key, localVarQueryParameter[key]);
             }
             for (const key in options.params) {
                 query.set(key, options.params[key]);
             }
             localVarUrlObj.search = (new URLSearchParams(query)).toString();
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Deletes a UserEntity by userEntityId
          * @param {number} idUserEntity 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         apiVversionUserUserEntityIdUserEntityDelete: async (idUserEntity: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'idUserEntity' is not null or undefined
             if (idUserEntity === null || idUserEntity === undefined) {
                 throw new RequiredError('idUserEntity','Required parameter idUserEntity was null or undefined when calling apiVversionUserUserEntityIdUserEntityDelete.');
             }
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserUserEntityIdUserEntityDelete.');
             }
             const localVarPath = `/api/v{version}/User/userEntity/{idUserEntity}`
                 .replace(`{${"idUserEntity"}}`, encodeURIComponent(String(idUserEntity)))
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, 'https://example.com');
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             // authentication Bearer required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Authorization")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
             }
 
             // authentication Userentity required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Userentity")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
             }
 
             const query = new URLSearchParams(localVarUrlObj.search);
             for (const key in localVarQueryParameter) {
                 query.set(key, localVarQueryParameter[key]);
             }
             for (const key in options.params) {
                 query.set(key, options.params[key]);
             }
             localVarUrlObj.search = (new URLSearchParams(query)).toString();
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                 options: localVarRequestOptions,
             };
         }, 
        /**
         * 
         * @summary Gets a list of employees according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserUserentitiesDisplaynameGet: async (version: string, ids?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserUserentitiesDisplaynameGet.');
            }
            const localVarPath = `/api/v{version}/User/userentities/displayname`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
         /**
          * 
          * @summary Gets the list of all UserEntities
          * @param {string} version 
          * @param {number} [pageNum] 
          * @param {number} [pageSize] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         apiVversionUserUserentitiesGet: async (version: string, pageNum?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionUserUserentitiesGet.');
             }
             const localVarPath = `/api/v{version}/User/userentities`
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, 'https://example.com');
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             // authentication Bearer required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Authorization")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
             }
 
             // authentication Userentity required
             if (configuration && configuration.apiKey) {
                 const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                     ? await configuration.apiKey("Userentity")
                     : await configuration.apiKey;
                 localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
             }
 
             if (pageNum !== undefined) {
                 localVarQueryParameter['pageNum'] = pageNum;
             }
 
             if (pageSize !== undefined) {
                 localVarQueryParameter['pageSize'] = pageSize;
             }
 
             const query = new URLSearchParams(localVarUrlObj.search);
             for (const key in localVarQueryParameter) {
                 query.set(key, localVarQueryParameter[key]);
             }
             for (const key in options.params) {
                 query.set(key, options.params[key]);
             }
             localVarUrlObj.search = (new URLSearchParams(query)).toString();
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                 options: localVarRequestOptions,
             };
         },
     }
 };
 
 /**
  * UserApi - functional programming interface
  * @export
  */
 export const UserApiFp = function(configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Gets the list of all User
          * @param {string} version 
          * @param {number} [pageNum] 
          * @param {number} [pageSize] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserSearchResponseResponse>>> {
             const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserAllGet(version, pageNum, pageSize, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Gets a User by emailAddress
          * @param {string} emailAddress 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserEmailAddressEmailAddressGet(emailAddress: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserResponse>>> {
             const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserEmailAddressEmailAddressGet(emailAddress, version, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Deletes a User by id
          * @param {number} id 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserIdDelete(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<StatusCodeResult>>> {
             const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserIdDelete(id, version, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Gets an User by id
          * @param {number} id 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserByIdResponseResponse>>> {
             const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserIdGet(id, version, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Updates the assigned security roles for a specific user. Payload requieres the UserId and the list of roles to be assigned.
          * @param {number} id 
          * @param {string} version 
          * @param {UpdateUserSecurityRolesCommand} [body] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserIdSecurityrolesPut(id: number, version: string, body?: UpdateUserSecurityRolesCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserResponse>>> {
             const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserIdSecurityrolesPut(id, version, body, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Creates a New User.
          * @param {string} version 
          * @param {CreateUserCommand} [body] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserPost(version: string, body?: CreateUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserResponse>>> {
             const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserPost(version, body, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Updates an existing User
          * @param {string} version 
          * @param {UpdateUserCommand} [body] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserPut(version: string, body?: UpdateUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserResponse>>> {
             const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserPut(version, body, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Searchs a User
          * @param {string} version 
          * @param {string} [term] 
          * @param {number} [pageNum] 
          * @param {number} [pageSize] 
          * @param {string} [sort] 
          * @param {string} [order] 
          * @param {Array<string>} [f] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserSearchResponseResponse>>> {
             const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserSearchGet(version, term, pageNum, pageSize, sort, order, f, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Gets all Users by typeahead
          * @param {string} text 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserTypeaheadTextGet(text: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserListResponse>>> {
             const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserTypeaheadTextGet(text, version, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Deletes a UserEntity by userEntityId
          * @param {number} idUserEntity 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserUserEntityIdUserEntityDelete(idUserEntity: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserResponse>>> {
             const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserUserEntityIdUserEntityDelete(idUserEntity, version, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
         * 
         * @summary Gets a list of employees according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserUserentitiesDisplaynameGet(version: string, ids?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<DisplayNameDTOSearchResponseResponse>>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserUserentitiesDisplaynameGet(version, ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },

         /**
          * 
          * @summary Gets the list of all UserEntities
          * @param {string} version 
          * @param {number} [pageNum] 
          * @param {number} [pageSize] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserUserentitiesGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserEntityDetailsSearchResponseResponse>>> {
             const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).apiVversionUserUserentitiesGet(version, pageNum, pageSize, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
     }
 };
 
 /**
  * UserApi - factory interface
  * @export
  */
 export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
     return {
         /**
          * 
          * @summary Gets the list of all User
          * @param {string} version 
          * @param {number} [pageNum] 
          * @param {number} [pageSize] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<UserSearchResponseResponse>> {
             return UserApiFp(configuration).apiVversionUserAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Gets a User by emailAddress
          * @param {string} emailAddress 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserEmailAddressEmailAddressGet(emailAddress: string, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserResponse>> {
             return UserApiFp(configuration).apiVversionUserEmailAddressEmailAddressGet(emailAddress, version, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Deletes a User by id
          * @param {number} id 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserIdDelete(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<StatusCodeResult>> {
             return UserApiFp(configuration).apiVversionUserIdDelete(id, version, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Gets an User by id
          * @param {number} id 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserByIdResponseResponse>> {
             return UserApiFp(configuration).apiVversionUserIdGet(id, version, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Updates the assigned security roles for a specific user. Payload requieres the UserId and the list of roles to be assigned.
          * @param {number} id 
          * @param {string} version 
          * @param {UpdateUserSecurityRolesCommand} [body] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserIdSecurityrolesPut(id: number, version: string, body?: UpdateUserSecurityRolesCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<UserResponse>> {
             return UserApiFp(configuration).apiVversionUserIdSecurityrolesPut(id, version, body, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Creates a New User.
          * @param {string} version 
          * @param {CreateUserCommand} [body] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserPost(version: string, body?: CreateUserCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<UserResponse>> {
             return UserApiFp(configuration).apiVversionUserPost(version, body, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Updates an existing User
          * @param {string} version 
          * @param {UpdateUserCommand} [body] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserPut(version: string, body?: UpdateUserCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<UserResponse>> {
             return UserApiFp(configuration).apiVversionUserPut(version, body, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Searchs a User
          * @param {string} version 
          * @param {string} [term] 
          * @param {number} [pageNum] 
          * @param {number} [pageSize] 
          * @param {string} [sort] 
          * @param {string} [order] 
          * @param {Array<string>} [f] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<UserSearchResponseResponse>> {
             return UserApiFp(configuration).apiVversionUserSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Gets all Users by typeahead
          * @param {string} text 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserTypeaheadTextGet(text: string, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserListResponse>> {
             return UserApiFp(configuration).apiVversionUserTypeaheadTextGet(text, version, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Deletes a UserEntity by userEntityId
          * @param {number} idUserEntity 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserUserEntityIdUserEntityDelete(idUserEntity: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserResponse>> {
             return UserApiFp(configuration).apiVversionUserUserEntityIdUserEntityDelete(idUserEntity, version, options).then((request) => request(axios, basePath));
         },
         /**
         * 
         * @summary Gets a list of employees according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserUserentitiesDisplaynameGet(version: string, ids?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<DisplayNameDTOSearchResponseResponse>> {
            return UserApiFp(configuration).apiVversionUserUserentitiesDisplaynameGet(version, ids, options).then((request) => request(axios, basePath));
        },
         /**
          * 
          * @summary Gets the list of all UserEntities
          * @param {string} version 
          * @param {number} [pageNum] 
          * @param {number} [pageSize] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async apiVversionUserUserentitiesGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<UserEntityDetailsSearchResponseResponse>> {
             return UserApiFp(configuration).apiVversionUserUserentitiesGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
         },
     };
 };
 
 /**
  * UserApi - object-oriented interface
  * @export
  * @class UserApi
  * @extends {BaseAPI}
  */
 export class UserApi extends BaseAPI {
     /**
      * 
      * @summary Gets the list of all User
      * @param {string} version 
      * @param {number} [pageNum] 
      * @param {number} [pageSize] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UserApi
      */
     public async apiVversionUserAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserSearchResponseResponse>> {
         return UserApiFp(this.configuration).apiVversionUserAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
     }
     /**
      * 
      * @summary Gets a User by emailAddress
      * @param {string} emailAddress 
      * @param {string} version 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UserApi
      */
     public async apiVversionUserEmailAddressEmailAddressGet(emailAddress: string, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserResponse>> {
         return UserApiFp(this.configuration).apiVversionUserEmailAddressEmailAddressGet(emailAddress, version, options).then((request) => request(this.axios, this.basePath));
     }
     /**
      * 
      * @summary Deletes a User by id
      * @param {number} id 
      * @param {string} version 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UserApi
      */
     public async apiVversionUserIdDelete(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<StatusCodeResult>> {
         return UserApiFp(this.configuration).apiVversionUserIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
     }
     /**
      * 
      * @summary Gets an User by id
      * @param {number} id 
      * @param {string} version 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UserApi
      */
     public async apiVversionUserIdGet(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserByIdResponseResponse>> {
         return UserApiFp(this.configuration).apiVversionUserIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
     }
     /**
      * 
      * @summary Updates the assigned security roles for a specific user. Payload requieres the UserId and the list of roles to be assigned.
      * @param {number} id 
      * @param {string} version 
      * @param {UpdateUserSecurityRolesCommand} [body] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UserApi
      */
     public async apiVversionUserIdSecurityrolesPut(id: number, version: string, body?: UpdateUserSecurityRolesCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserResponse>> {
         return UserApiFp(this.configuration).apiVversionUserIdSecurityrolesPut(id, version, body, options).then((request) => request(this.axios, this.basePath));
     }
     /**
      * 
      * @summary Creates a New User.
      * @param {string} version 
      * @param {CreateUserCommand} [body] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UserApi
      */
     public async apiVversionUserPost(version: string, body?: CreateUserCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserResponse>> {
         return UserApiFp(this.configuration).apiVversionUserPost(version, body, options).then((request) => request(this.axios, this.basePath));
     }
     /**
      * 
      * @summary Updates an existing User
      * @param {string} version 
      * @param {UpdateUserCommand} [body] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UserApi
      */
     public async apiVversionUserPut(version: string, body?: UpdateUserCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserResponse>> {
         return UserApiFp(this.configuration).apiVversionUserPut(version, body, options).then((request) => request(this.axios, this.basePath));
     }
     /**
      * 
      * @summary Searchs a User
      * @param {string} version 
      * @param {string} [term] 
      * @param {number} [pageNum] 
      * @param {number} [pageSize] 
      * @param {string} [sort] 
      * @param {string} [order] 
      * @param {Array<string>} [f] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UserApi
      */
     public async apiVversionUserSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserSearchResponseResponse>> {
         return UserApiFp(this.configuration).apiVversionUserSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(this.axios, this.basePath));
     }
     /**
      * 
      * @summary Gets all Users by typeahead
      * @param {string} text 
      * @param {string} version 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UserApi
      */
     public async apiVversionUserTypeaheadTextGet(text: string, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserListResponse>> {
         return UserApiFp(this.configuration).apiVversionUserTypeaheadTextGet(text, version, options).then((request) => request(this.axios, this.basePath));
     }
     /**
      * 
      * @summary Deletes a UserEntity by userEntityId
      * @param {number} idUserEntity 
      * @param {string} version 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UserApi
      */
     public async apiVversionUserUserEntityIdUserEntityDelete(idUserEntity: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserResponse>> {
         return UserApiFp(this.configuration).apiVversionUserUserEntityIdUserEntityDelete(idUserEntity, version, options).then((request) => request(this.axios, this.basePath));
     }
/**
     * 
     * @summary Gets a list of employees according to user entity based visibility rules.
     * @param {string} version 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
public async apiVversionUserUserentitiesDisplaynameGet(version: string, ids?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<DisplayNameDTOSearchResponseResponse>> {
    return UserApiFp(this.configuration).apiVversionUserUserentitiesDisplaynameGet(version, ids, options).then((request) => request(this.axios, this.basePath));
}     /**
      * 
      * @summary Gets the list of all UserEntities
      * @param {string} version 
      * @param {number} [pageNum] 
      * @param {number} [pageSize] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof UserApi
      */
     public async apiVversionUserUserentitiesGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserEntityDetailsSearchResponseResponse>> {
         return UserApiFp(this.configuration).apiVversionUserUserentitiesGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
     }
 }
 
import React, { useEffect, useState } from 'react';
import ClientSelect from './ClientSelect';
import { Card, CardHeader, Label, FormGroup, CardBody, Row, Col } from 'reactstrap';
import { PermissionsGate } from 'components/PermissionsGate';
import { ROLES, SCOPES } from 'common/permissions';
import RequiredField from '../RequiredField';
import intl from 'react-intl-universal';
import { HiOutlineEye } from 'react-icons/hi';
import { useNavigate } from 'react-router';

const ProjectForm = ({ errors, input, handleInputChange, handleClientChange, clients, selectedClient, disabled, idProject, setErrors, validate }) => {
    const [jiraToken, setJiraToken] = useState(input.jiraToken == '**encrypted**' ? '**encrypted**' : input.jiraToken);
    const [jiraTokenInputFlag, setJiraTokenInputFlag] = useState(false);
    const [jiraTokenFlag, setJiraTokenFlag] = useState(false);

    useEffect(() => {
        if (!disabled) {
            setJiraToken(input.jiraToken == '**encrypted**' ? '' : input.jiraToken);
        }
        else {
            setJiraToken(input.jiraToken == '' ? '' : input.jiraToken);
        }
        setJiraTokenInputFlag(input.jiraToken === "" || input.jiraToken === null ? true : jiraTokenFlag);

    }, [disabled, input, jiraTokenFlag]);

    const handleJiraTokenChange = (e) => {
        console.log({ e });
        setJiraToken(e.target.value);
        handleInputChange(e);
        setJiraTokenFlag(true);
    }
    
    const handleJiraTokenTextChange = (e) => {
        if (e.keyCode == 8 || e.keyCode == 46) {
            input.jiraToken = "";
            setJiraTokenFlag(true);
            setErrors(validate({
                ...input,
                "jiraToken": ""
            })); 
        }
    }

    const navigate = useNavigate();

    const handleClientDetail = (e) => {
        e.preventDefault();
        navigate('/client/detail/' + selectedClient);
    };
    
    return (
        <form className="my-4">
            <fieldset>
                <div className="row justify-content-around">
                    <div className="col-md-12 justify-content-between align-items-center mb-2">
                        <div className='d-inline-flex col-12 col-md-5 flex-column'>
                            <label htmlFor="name" className={`form-label ${errors.name ? 'text-danger' : ''}`}>
                                {intl.get('projectForm.name')} {errors.name && (<span className='text-danger'>{errors.name}</span>)}
                            </label>
                            <input
                                type="text"
                                className='form-control'
                                id="name"
                                name="name"
                                placeholder={intl.get('projectForm.placeholder.name')}
                                value={input.name}
                                onChange={handleInputChange}
                                disabled={disabled}
                            ></input>
                        </div>
                        <div className='d-inline-flex col-12 col-md-4 flex-column px-2'>
                            <Label check for="idClient" className={`form-label d-flex align-items-center ${errors.idClient ? 'text-danger' : ''}`}>
                                {intl.get('projectForm.client')}&nbsp;{errors.idClient && (<span className='text-danger'>{errors.idClient}</span>)}
                                {
                                idProject &&
                                <PermissionsGate viewScopes={[SCOPES['clients.read']]} editScopes={[SCOPES['clients.read']]} viewRoles={[ROLES.projectReader]} editRoles={[ROLES.projectReader]}>
                                    <a className='td-icon d-flex' href='#' title='View Client' onClick={handleClientDetail}>
                                    <HiOutlineEye/>
                                    </a>
                                </PermissionsGate>
                                }
                            </Label>
                            <ClientSelect
                                handleClientChange={handleClientChange}
                                clients={clients}
                                selectedClient={selectedClient}
                                disabled={disabled}
                                newUser={false}
                                id={null}
                                securityRoles={null}
                                edit={null}
                                setHiddenRole={null}
                            />
                        </div>
                        <div className='d-inline-flex col-12 col-md-3 flex-column'>
                            <PermissionsGate viewScopes={[SCOPES['budget.read']]} editScopes={[SCOPES['budget.edit']]} viewRoles={[]} editRoles={[]} >
                            <label htmlFor="budget" className={`form-label ${errors.budget ? 'text-danger' : ''}`}>
                                {intl.get('projectForm.budget')} {errors.budget && (<span className='text-danger'>{errors.budget}</span>)}
                            </label>
                            <input
                                type="number"
                                className='form-control'
                                id="budget"
                                name="budget"
                                min="0"
                                placeholder={intl.get('projectForm.placeholder.budget')}
                                value={input.budget}
                                onChange={handleInputChange}
                                disabled={disabled}
                                required={true}
                            ></input>
                            </PermissionsGate>
                        </div>
                    </div>
                    <RequiredField/>
                    <div className="col-12 mt-3">
                        <Card>
                            <CardHeader className='px-0'>{intl.get('projectForm.jira.header')}</CardHeader>
                            <CardBody className='px-0'>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <label htmlFor="jiraUser" className={`form-label ${errors.jiraUser ? 'text-danger' : ''}`}>
                                                {intl.get('projectForm.jira.user')} {errors.jiraUser && (<span className='text-danger'>{errors.jiraUser}</span>)}
                                            </label>
                                            <input
                                                type="text"
                                                className={errors.jiraUser ? 'client-danger form-control mb-2' : "form-control mb-2"}
                                                id="jiraUser"
                                                name="jiraUser"
                                                placeholder={intl.get('projectForm.jira.placeholder.user')}
                                                value={input.jiraUser || ''}
                                                onChange={handleInputChange}
                                                disabled={disabled}
                                                autoComplete="do-not-autofill"
                                            ></input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <label htmlFor="jiraToken" className={`form-label ${errors.jiraToken ? 'text-danger' : ''}`}>
                                                {intl.get('projectForm.jira.access')} {errors.jiraToken && (<span className='text-danger'>{errors.jiraToken}</span>)}
                                            </label>
                                            {
                                                jiraTokenInputFlag ?
                                                <input
                                                    className={`form-control mb-2 jira-token${errors.jiraToken ? ' client-danger' : ''}`}
                                                    type={ disabled ? 'password' : 'text'}
                                                    id="jiraToken"
                                                    name="jiraToken"
                                                    placeholder={intl.get('projectForm.jira.placeholder.access')}
                                                    value={jiraToken}
                                                    onChange={handleJiraTokenChange}
                                                    disabled={disabled}
                                                    autoComplete="do-not-autofill"
                                                ></input>
                                                :
                                                <input
                                                    className={`form-control mb-2 jira-token`}
                                                    type='password'
                                                    id="jiraToken"
                                                    name="jiraToken"
                                                    placeholder={intl.get('projectForm.jira.placeholder.access')}
                                                    value={input.jiraToken}
                                                    onKeyDown={handleJiraTokenTextChange}
                                                    disabled={disabled}
                                                    autoComplete="do-not-autofill"
                                                ></input>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <label htmlFor="jiraProjectKey" className={`form-label ${errors.jiraProjectKey ? 'text-danger' : ''}`}>
                                                {intl.get('projectForm.jira.projectKey')} {errors.jiraProjectKey && (<span className='text-danger'>{errors.jiraProjectKey}</span>)}
                                            </label>
                                            <input
                                                type="text"
                                                className={errors.jiraProjectKey ? 'client-danger form-control mb-2' : "form-control mb-2"}
                                                id="jiraProjectKey"
                                                name="jiraProjectKey"
                                                placeholder={intl.get('projectForm.jira.placeholder.projectKey')}
                                                value={input.jiraProjectKey || ''}
                                                onChange={handleInputChange}
                                                disabled={disabled}
                                            ></input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <label htmlFor="jiraUrl" className={`form-label ${errors.jiraUrl ? 'text-danger' : ''}`}>
                                                {intl.get('projectForm.jira.url')} {errors.jiraUrl && (<span className='text-danger'>{errors.jiraUrl}</span>)}
                                            </label>
                                            <input
                                                type="text"
                                                className={errors.jiraUrl ? 'client-danger form-control mb-2' : "form-control mb-2"}
                                                id="jiraUrl"
                                                name="jiraUrl"
                                                placeholder={intl.get('projectForm.jira.placeholder.url')}
                                                value={input.jiraUrl || ''}
                                                onChange={handleInputChange}
                                                disabled={disabled}
                                            ></input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </fieldset>
        </form >
    )
}

export default ProjectForm;
import React, { useState, FC } from 'react';
import { getUserEntity } from 'common/UserEntityProvider';
import { ENTITY_TYPE } from 'common/constants';
import { hasPermission } from './hasPermission';
import { hasRole } from './hasPermission';

type PermissionsGateProps = {
  children: any,
  RenderError?: any | null | undefined,
  viewErrorProps?: String[] ,
  editErrorProps?: String[] ,
  viewScopes: String[],
  editScopes: String[],
  viewRoles: String[] ,
  editRoles: String[],
  entityType?: number 
}

export const PermissionsGate: FC<PermissionsGateProps> =  ({
  children,
  RenderError = ()=><></>,
  viewErrorProps,
  editErrorProps,
  viewScopes,
  editScopes,
  viewRoles,
  editRoles,
  entityType=1
}) => {
  
  const viewGranted = checkPermission(viewScopes, viewRoles, entityType);
  const editGranted = checkPermission(editScopes, editRoles, entityType);

  if (!viewGranted && !viewErrorProps){
    return <RenderError />;
  }

  if (!viewGranted && viewErrorProps){
    return React.Children.map(children, (child => pgCloneElement(child, { ...viewErrorProps })));
  }

  if (!editGranted && !editErrorProps){
    return React.Children.map(children, (child => pgCloneElement(child, {})));
  }

  if (!editGranted && editErrorProps){
    return React.Children.map(children, (child => pgCloneElement(child, { ...editErrorProps })));
  }
  return <>{children}</>;
}

function checkPermission(scopes, roles, entityType) {
  const userEntity = getUserEntity();
  return (hasPermission({ scopes }) || hasRole({ userRoles: [], roles })) &&
    (entityType === userEntity?.entityType || entityType === ENTITY_TYPE.ANY);
}

function pgCloneElement(child, props)
{
  switch(child.type)
  {
    case 'a':
      return <a className="disabled" onClick={(e)=>{e.preventDefault();}}>{child.props.children} </a>;
    case 'button':
        return <button className={child.props.className} disabled onClick={(e)=>{e.preventDefault();}}>{child.props.children}</button>;
    case 'td':
    case 'tr':
      return <td></td>;
    default:
      return child;
  }
}

import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './scss/global/Global.scss';
import { BASE_PATH } from './axiosApi/base';
import intl from "react-intl-universal";
import { Settings}  from "./common/settings";
const local = navigator.language;
const languageStorage = localStorage.language;

let language;
if (!languageStorage) {
  if (local === 'es-ES') {
    language = 'es';
  } else {
    language = 'en';
  }
} else {
  language = languageStorage;
}

// Function to fetch locales from the API
async function fetchLocales() {
  try {
    const response = await fetch(  Settings.getInstance().BASE_API_URL_LOCALE + '/api/v1/Locale');
    if (!response.ok) {
      throw new Error('Failed to fetch locales');
    }
    const data = await response.json();
    const locales = {};

    // Parse the data into the required format for react-intl-universal
    for (const [locale, translations] of Object.entries(data)) {
      locales[locale] = translations;
    }

    return locales;
  } catch (error) {
    console.error('Error fetching locales:', error);
    return null;
  }
}

// Initialize intl after fetching locales
async function initializeIntl() {
  const locales = await fetchLocales();
  if (locales) {
    await intl.init({
      locales,
      currentLocale: language
    });
  } else {
    console.error('Failed to initialize locales, using fallback language.');
  }
}

initializeIntl().then(() => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

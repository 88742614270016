/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Boilerplate API
 * You need to change all the object names to your project ones
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateInvoiceCommand } from '../models/create-invoice-command';
import { InvoiceResponse } from '../models/invoice-response';
import { InvoiceSearchResponseResponse } from '../models/invoice-search-response-response';
import { StringResponse } from '../models';
import { UpdateInvoiceCommand } from '../models/update-invoice-command';
/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionInvoiceIdGet: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionInvoiceIdGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionInvoiceIdGet.');
            }
            const localVarPath = `/api/v{version}/Invoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateInvoiceCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionInvoicePost: async (version: string, body?: CreateInvoiceCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionInvoicePost.');
            }
            const localVarPath = `/api/v{version}/Invoice`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {UpdateInvoiceCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionInvoicePut: async (version: string, body?: UpdateInvoiceCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionInvoicePut.');
            }
            const localVarPath = `/api/v{version}/Invoice`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionInvoiceSearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionInvoiceSearchGet.');
            }
            const localVarPath = `/api/v{version}/Invoice/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionInvoiceSearchIssuedGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionInvoiceSearchIssuedGet.');
            }
            const localVarPath = `/api/v{version}/Invoice/searchIssued`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionInvoiceIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InvoiceResponse>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).apiVversionInvoiceIdGet(id, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateInvoiceCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionInvoicePost(version: string, body?: CreateInvoiceCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InvoiceResponse>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).apiVversionInvoicePost(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {UpdateInvoiceCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionInvoicePut(version: string, body?: UpdateInvoiceCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InvoiceResponse>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).apiVversionInvoicePut(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionInvoiceSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InvoiceSearchResponseResponse>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).apiVversionInvoiceSearchGet(version, term, pageNum, pageSize, sort, order, f, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionInvoiceSearchIssuedGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InvoiceSearchResponseResponse>>> {
            const localVarAxiosArgs = await InvoiceApiAxiosParamCreator(configuration).apiVversionInvoiceSearchIssuedGet(version, term, pageNum, pageSize, sort, order, f, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionInvoiceIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<InvoiceResponse>> {
            return InvoiceApiFp(configuration).apiVversionInvoiceIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateInvoiceCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionInvoicePost(version: string, body?: CreateInvoiceCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<InvoiceResponse>> {
            return InvoiceApiFp(configuration).apiVversionInvoicePost(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {UpdateInvoiceCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionInvoicePut(version: string, body?: UpdateInvoiceCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<InvoiceResponse>> {
            return InvoiceApiFp(configuration).apiVversionInvoicePut(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionInvoiceSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<InvoiceSearchResponseResponse>> {
            return InvoiceApiFp(configuration).apiVversionInvoiceSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionInvoiceSearchIssuedGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<InvoiceSearchResponseResponse>> {
            return InvoiceApiFp(configuration).apiVversionInvoiceSearchIssuedGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async apiVversionInvoiceIdGet(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<InvoiceResponse>> {
        return InvoiceApiFp(this.configuration).apiVversionInvoiceIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} version 
     * @param {CreateInvoiceCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async apiVversionInvoicePost(version: string, body?: CreateInvoiceCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<InvoiceResponse>> {
        return InvoiceApiFp(this.configuration).apiVversionInvoicePost(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} version 
     * @param {UpdateInvoiceCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async apiVversionInvoicePut(version: string, body?: UpdateInvoiceCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<InvoiceResponse>> {
        return InvoiceApiFp(this.configuration).apiVversionInvoicePut(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async apiVversionInvoiceSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<InvoiceSearchResponseResponse>> {
        return InvoiceApiFp(this.configuration).apiVversionInvoiceSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public async apiVversionInvoiceSearchIssuedGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<InvoiceSearchResponseResponse>> {
        return InvoiceApiFp(this.configuration).apiVversionInvoiceSearchIssuedGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(this.axios, this.basePath));
    }
}

import React, { useState } from "react";import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { FaChevronDown } from "react-icons/fa";
import Api from '../../axiosApi/api';
import { handleAPIError } from "common/errorHandler";
import { useToastMessageQueue } from "components/ToastMessages/ToastMessageProvider";
import { useApi } from '../../api/ApiProvider';
import { useWorkflow } from './WorkflowContext';
import intl from 'react-intl-universal';
import Spinner from "components/Spinner";
import { LogarithmicScale } from "chart.js";
import { Background } from "@xyflow/react";

type DisplayName =
{ Id: number,
     Name:string

}

const WorkflowLogEntries = ({ idModule, idEntity }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({});
  const [expanded, setExpanded] = useState(false);

  const [displayName, setDisplayName] = useState<DisplayName[] | null>(null);
  const api: Api = useApi();
  const toast = useToastMessageQueue();
  const { workflow, loading:workflowLoading, errors:error } = useWorkflow();
  const fetchLogs = async () => {
    try {
      setLoading(true);
      const response = await api.workflowApi.apiVversionWorkflowLogsIdModuleIdEntityGet(idModule, idEntity ,"1").then((response) => {
        const logEntries = response.data?.data?.queryResult || [];
        // Sort logs by timestamp desc
        logEntries.sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp));
        setLogs(logEntries);
            // Step 1: Extract userentities
        const userentities = logEntries.map(entry => entry.userEntityId);

        // Step 2: Remove duplicates
        const uniqueUserentities = [...new Set(userentities)];

        // Step 3: Join into a comma-separated string
        const userentitiesList = uniqueUserentities.join(',');


        const displayNames = api.userApi.apiVversionUserUserentitiesDisplaynameGet("1",userentitiesList).then((response) => {
          const displayNames = response.data?.data?.queryResult || [];
          
          // Sort logs by timestamp desc
          setDisplayName(displayNames.map(x=>{return {Id:x.id,Name:x.displayName}}));    
          }).catch((error) => {
              handleAPIError(error, toast, errors);
              setErrors({...errors});
              setLoading(false);
          });
        
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
            setLoading(false);
        });
      
    } catch (error) {
      console.error("Error fetching logs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = () => {
    if (!expanded && logs.length === 0) {
      fetchLogs();
    }
    setExpanded(!expanded);
  };

    function getStateName(toState: any): React.ReactNode {
        var state = workflow?.states?.find((state) => state.id === toState);
        console.log(state);
        return  intl.get(state.labelKey|| "missingkey");
    }

  function getUserName(userEntityId: any): React.ReactNode {
    return displayName?.find((x) => x.Id === userEntityId)?.Name;
  }

  return (
    <div>
    <Accordion expanded={expanded} onChange={handleToggle}>
      <AccordionSummary
        expandIcon={<FaChevronDown />}
        aria-controls="log-entries-content"
        id="log-entries-header"
      >
        <Typography>{intl.get("workflowlog.title")}</Typography>
      </AccordionSummary>
      <AccordionDetails className="MuiAccordionDetails-root2">
        {loading|| workflowLoading ? (
          <Spinner />
        ) : (
          <TableContainer component={Paper}  >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>{intl.get("workflowlog.theader.datetime")}</strong></TableCell>
                  <TableCell><strong>{intl.get("workflowlog.theader.user")}</strong></TableCell>
                  <TableCell><strong>{intl.get("workflowlog.theader.state")}</strong></TableCell>
                  <TableCell><strong>{intl.get("workflowlog.theader.comments")}</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell>{
                      log.timestamp ? new Date(log.timestamp).toLocaleString() : "N/A"
                    }</TableCell>
                    <TableCell>{getUserName(log.userEntityId) || "N/A"}</TableCell>
                    <TableCell>{getStateName(log.toState) || "N/A"}</TableCell>
                    <TableCell>{log.comments || "N/A"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AccordionDetails>
    </Accordion>
    </div>
  );
};

export default WorkflowLogEntries;

import React from 'react';
import { useNavigate } from 'react-router';
import { Client } from 'axiosApi/models';

type projectCompactRowProps = {
    id: number,
    name: string,
    client: Client,
    budget: number,
    className: string
}

const ProjectCompactRow = ({id, name, client, budget, className}: projectCompactRowProps) => {

    const navigate = useNavigate();

    const handleClickRow = () => {
        navigate('/project/detail/' + id);
    };

    return (
        <>
            {
                name ?
                <tr onClick={handleClickRow} className={className}>
                    <td>{name}</td>
                    <td>{client.name}</td>
                    <td>{budget}</td>
                </tr> :
                null
            }
        </>
    )
}

export default ProjectCompactRow;


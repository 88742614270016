import React from 'react';
import { Link } from 'react-router';
import SearchBar from './SearchBar';
import '../scss/Navbar.scss';
import { HiOutlinePlus } from 'react-icons/hi';
import { Tooltip as ReactTooltip } from "react-tooltip";
import intl from 'react-intl-universal';

const Navbar = ({ onSearch, limit, setLimit }) => {
    
    return (
        <nav className='navbar container-fluid'>
            <SearchBar onSearch={onSearch}/>
            <Link to= '/client/new'>
                <button className='btn btn-primary p-1 plusButton' type='button' id='newClient' data-tooltip-id='tooltipButton'>
                    <HiOutlinePlus className='plusIcon'/>
                </button>
            </Link>
            <ReactTooltip 
                id='tooltipButton'
                place='left'
                content={intl.get('navbar.newClients.button')}
            />
        </nav>
    )
}

export default Navbar;
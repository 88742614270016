import React, {Dispatch, FC, SetStateAction, useState} from 'react';
import '../../scss/AddressRow.scss';
import {useApi} from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { PermissionsGate}  from '../PermissionsGate';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import intl from 'react-intl-universal';
import { handleAPIError } from '../../common/errorHandler';
import ModalPersona from './ModalPersona';
import { Persona } from 'axiosApi/models/persona';
import { Chip } from '@mui/material';
import { CorporateEntity } from 'axiosApi/models/corporate-entity';

type PersonaRowProps = {
        disabled: boolean;
        errors:{};
        setErrors: SetStateAction<{}>;
        persona: Persona;
        setPersona: Dispatch<SetStateAction<Persona>>;
        corporateEntities: CorporateEntity[];
        setcorporateEntities: Dispatch<SetStateAction<CorporateEntity[]>>
        editScopes : string[];
        deleteScopes: string[];
        inputProvider: any;
        entityId : number
        idEntityType : number
        personaExist: boolean;
        setPersonaExist: Dispatch<SetStateAction<boolean>>;
        setInputProvider: (e : any) => void;
        handleDelete:(e : any) => void;
}

const PersonaRow: FC<PersonaRowProps> = (props) =>{

    const {editScopes, disabled, deleteScopes, persona, setPersona, corporateEntities, setcorporateEntities,
           handleDelete, personaExist, setPersonaExist, inputProvider = null, setInputProvider = null, entityId, idEntityType } = props;

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [isPersonaRowDetailOpen, setIsPersonaRowDetailOpen] = useState(false);

    const closePersonaRowDetail = () => setIsPersonaRowDetailOpen(false);

    const handlePersonaRowDetailOpen = () => {
        setIsPersonaRowDetailOpen(true);
    };

    return (
        <>
            {
                (personaExist && persona) && 
                <tr>
                    <td>{persona.name}</td>
                    <td>{intl.get(persona.personaType.labelKey)}</td>
                    <td>{persona.taxID}</td>
                    <td className='text-start'>
                        {persona.corporateEntities.length > 0 &&
                        persona.corporateEntities.map((corporateEntity,index) => 
                            <Chip
                                key={index}
                                label={corporateEntity.legalName}
                                variant="outlined"
                                size="small"
                                className="text-white bg-secondary me-1 border-white fw-semibold"
                            />)
                        }
                    </td>                    
                    <td className='text-center'>
                    <PermissionsGate viewScopes={editScopes} editScopes={editScopes} viewRoles={[]} editRoles={[]} >
                        <a className={ disabled === true ? 'td-icon disabled mx-1' : 'td-icon mx-1'} onClick={handlePersonaRowDetailOpen} title={intl.get('personaRow.editButton.title')}>
                            <HiOutlinePencilAlt/>
                        </a>
                    </PermissionsGate>
                    <ModalPersona
                        isOpen={isPersonaRowDetailOpen} 
                        closeModal={closePersonaRowDetail} 
                        persona={persona} 
                        setPersona={setPersona} 
                        entityId = {entityId}
                        idEntityType = {idEntityType}
                        corporateEntities={corporateEntities}
                        inputProvider={inputProvider} 
                        setInputProvider={setInputProvider}
                        setPersonaExist={setPersonaExist}
                        personaExists={personaExist}
                    />    
                        <PermissionsGate viewScopes={deleteScopes} editScopes={deleteScopes} viewRoles={[]} editRoles={[]} > 
                            <a className={ disabled === true ? 'td-icon disabled mx-1' : 'td-icon mx-1'} onClick={handleDelete} title={intl.get('personaRow.deleteButton.title')}>
                                <HiOutlineTrash/>
                            </a>
                        </PermissionsGate>
                    </td>
                </tr> 
            }
        </>
    )   
}

export default PersonaRow;
import React from 'react'
import { Box, Typography } from '@mui/material'
import { MdArrowBack } from 'react-icons/md'
import { Link } from 'react-router'
import intl from "react-intl-universal";
import '../../scss/common/NotFoundPage.scss'

type ErrorTemplateFoundPageProps = {
    statusCode: number,
    title: string,
    message?: string
};

export default function ErrorTemplateFoundPage({
    statusCode,
    title,
    message
}: ErrorTemplateFoundPageProps) {
  return (<div className='container'>
    <div className='card mt-4 position-absolute top-50 start-50 translate-middle'>
        <div className='container card-body flex justify-content-center align-items-center text-center'>
            <Box className="not-found-page-container margin-auto" sx={{backgroundImage: 'url("./biglogo.png")'}}>
                <Typography className='text-center' sx={{fontSize: '8rem', fontWeight: 'bold'}}>
                    {statusCode}
                </Typography>
            </Box>
            <h2 className='text-center'>{title}</h2>
            {message && <p className='my-4'>{message}</p>}
            <Link to='/' className='text-center d-block mt-3 text-decoration-none'>
                <MdArrowBack /> {intl.get('errorPage.back')} 
            </Link>
        </div>
    </div>
  </div>)
}

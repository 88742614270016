import React, { useEffect, useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import { DateRange, DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';
import { BsCalendarDate } from "react-icons/bs";
import { formatDateRange } from 'common/utils';

type DateRangePickerDialogProps = {
  selected: DateRange,
  setRange: Function,
  className: string,
  inputName: string,
  required: boolean,
  disabled: boolean
}

export default function DateRangePickerDialog({ selected, setRange, className, inputName, required, disabled }: DateRangePickerDialogProps) {
  const [inputValue, setInputValue] = useState<string>('');
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleRangeSelect = (date: DateRange) => {
    if (date !== undefined) {
        setRange(date);
        const formattedDate = formatDateRange(date);
      if (formattedDate) {
        setInputValue(formattedDate);
      } else {
        setInputValue('');
      }
    }
  };

  useEffect(() => {
    if (selected)
      setInputValue(formatDateRange(selected as DateRange));
    else{
      setInputValue('');
    }
  }, [selected]);


  return (
    <div className={className}>
      <div className='position-relative' ref={popperRef}>
        <button
            ref={buttonRef}
            type="button"
            className="button-reset button-icon"
            aria-label="Pick a date range"
            onClick={handleButtonClick}
            disabled={disabled}
            >
          <BsCalendarDate></BsCalendarDate>
        </button>
        <input
          name={inputName}
          type="text"
          value={inputValue}
          className="input-reset form-control"
          required={required}
        />
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: buttonRef.current
          }}
        >
          <div
            tabIndex={-1}
            style={{ ...popper.styles.popper, zIndex: 9999 }}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="range"
              selected={selected}
              onSelect={e => handleRangeSelect(e)}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
}
import React, { FC } from 'react';
import { FaEdit } from 'react-icons/fa';
import { toMinutes } from 'common/utils';
import { Timesheet } from 'axiosApi/models';
import { IMaskInput } from 'react-imask';
import './../../scss/Timesheet/TimesheetDayDuration.scss';

type TimesheetRowProps = {
    duration: string,
    id: number,
    onClick: Function,
    idTimesheet: number,
    handleDurationChange: Function,
    disabled: boolean,
    handleDurationSave: Function,
    timesheet: Timesheet,
    date: string,
    description: string
}



const TimesheetDayDuration: FC<TimesheetRowProps> = ({ duration, id, onClick, idTimesheet, handleDurationChange, disabled, handleDurationSave, timesheet, date, description }) => {
    var saving = false;
    const localHandleDurationSave = (target) => {
        const duration = toMinutes(target.value)
        if ( !isNaN(duration) && ((timesheet && timesheet.duration !== duration) || (!timesheet && !!duration))) {
            handleDurationSave({ ...timesheet, duration: toMinutes(target.value), date: date });
        }
    }
    const handleKeyDown = (event) => {

        switch (event.key) {
            case "Enter":
                if(!saving)
                {
                    saving = true;
                    const duration = toMinutes(event.target.value)
                    if ( !isNaN(duration) && ((timesheet && timesheet.duration !== duration) || !timesheet && !!duration)) {
                        handleDurationSave({ ...timesheet, duration: toMinutes(event.target.value), date: date });
                    }
                    setTimeout(()=>{saving = false;},3000);
                }

                break;

        }
    }
    return (
        <div className="position-relative" key={id}>
            <IMaskInput
                mask="00:00"
                disabled={true}
                style={{ position: 'relative' }}
                value={duration}
                type="text"
                onAccept={(value) => handleDurationChange(value)}
                onBlur={(e) => localHandleDurationSave(e.target)}
                onKeyDown={handleKeyDown}
                className="time-input form-control col-6 white"
                placeholder="hh:mm"
                autoComplete="off"
                title={description}
            />
            <div className={'input-group-flying-icon  icon-hand' + (description.length>0? ' text-green ':' text-dark-light') }>
                <FaEdit onClick={() => !disabled && onClick(idTimesheet)}  title={description} color={disabled?"lightgrey":""} style={{cursor: disabled ? 'not-allowed' : 'pointer'}}/>
            </div>
        </div>
    );
}

export default TimesheetDayDuration;
import React, { useEffect, useState } from 'react'
import { Drawer, Typography, Stack, TextField, Button, Box, InputLabel, Switch } from '@mui/material'
import IconPicker from 'react-icons-picker'
import '../../../scss/Workflow/FloatingEdgeDrawer.scss'
import intl from 'react-intl-universal'
import ColorPicker from 'components/ColorPicker'
import { Connection, MarkerType } from '@xyflow/react'
import { FloatingEdgeType } from './FloatingEdge'
import { ScopeSearchResponse } from 'axiosApi/models'
import { useApi } from '../../../api/ApiProvider'
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider'
import Api from 'axiosApi/api'
import ChipSelector from './ChipSelector'
import { FaSave, FaTrash } from 'react-icons/fa'
import { handleAPIError } from 'common/errorHandler';
import ErrorSummary from 'components/common/ErrorSummary'
import { Scope } from 'axiosApi/models/scope'

type FloatingEdgeDrawerProps = {
    selectedEdge: FloatingEdgeType | Connection
    onClose: () => void
    disabled?: boolean
    saveEdge: (edge: FloatingEdgeType) => void
    deleteEdge: (edgeId: string) => void
}

const FloatingEdgeDrawer: React.FC<FloatingEdgeDrawerProps> = ({ selectedEdge, onClose, disabled, saveEdge, deleteEdge }) => {

    const isFloatingEdgeType = (edge: any): edge is FloatingEdgeType => {
        return (edge as FloatingEdgeType)?.data !== undefined;
    };
    const [selectedIcon, setSelectedIcon] = useState('');
    const [actionName, setActionName] = useState('')
    const [labelKey, setLabelKey] = useState('')
    const [color, setColor] = useState('#000')
    const [scope, setScope] = useState<string>("")
    const [errors, setErrors] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [allowComments, setAllowComments] = useState(false);
    const handleLabelKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => setLabelKey(event.target.value)
    const handleActionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setActionName(event.target.value)

    useEffect(() => {
        
        const floatingEdge = selectedEdge as FloatingEdgeType;
        const connection = selectedEdge as Connection;
        if(isFloatingEdgeType(selectedEdge)){
            setEditMode(true)
            setActionName(floatingEdge?.data?.actionName)
            setLabelKey(floatingEdge?.data?.labelKey)
            setAllowComments(floatingEdge?.data?.AllowComments)
            setSelectedIcon(floatingEdge?.data?.Icon ? floatingEdge?.data?.Icon : '')
            setColor(floatingEdge?.data?.DisplayColor ? floatingEdge?.data?.DisplayColor : '#000')
            setScope(floatingEdge?.data?.scopes ? floatingEdge?.data?.scopes : "")
        }else if(connection){
            setEditMode(false)
            setActionName('')
            setLabelKey('')
            setAllowComments(false)
            setSelectedIcon('')
            setColor('#000')
        }
    }, [selectedEdge])

    const [loading, setLoading] = useState(true)
    const [scopes, setScopes] = useState<Scope[]>([])

    const api: Api = useApi();
    const toast = useToastMessageQueue();

    const fetchScopes = () => {
        setLoading(true)
        api.scopeApi.apiVversionScopeAllGet('1', 1, 100, {})
        .then((response) => {
            if (response.data.data === null) {
                setScopes(response.data.data.queryResult);
                
            } else {
                setScopes(response.data.data.queryResult);
            }
        })
        .catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        }).finally(() => {
            setLoading(false)
        });
    }

    const value = (selectedEdge as FloatingEdgeType)?.data?.labelKey;

    const handleViewScopeChange = (newValue: any) => { setScope(newValue.id)}

    const handleSave = () => {
        setErrors({})
        if (!actionName) {
            setErrors({ summary: ['Action name is required']})
            return
        }
        saveEdge({
            ...selectedEdge as FloatingEdgeType,
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 30,
                height: 30,
                color: '#black',
            },
            label: labelKey,
            data: {
                actionName: actionName,
                labelKey: labelKey,
                Icon: selectedIcon,
                AllowComments: allowComments,
                DisplayColor: color,
                extraAttributes: (selectedEdge as FloatingEdgeType)?.data?.extraAttributes || {},
                scopes:scope
            },
            type: 'floating',
            id: editMode ? (selectedEdge as FloatingEdgeType)?.id : null
        })
    }

    const handleDelete = (edgeId: string) => {
        deleteEdge && deleteEdge(edgeId)
    };

    useEffect(() => {
        fetchScopes()
    }, [])

    return (
        <Drawer anchor="right" open={!!selectedEdge} onClose={onClose} classes={{ paper: 'drawer p-4' }}>
            <Typography variant="h4" color="primary">
                {editMode ? "Edit Transition" : "Create Transition"}
            </Typography>
            <Stack spacing={2} mt={2} mb={2} className="floating-edge-form">
                <ErrorSummary errors={errors} showEverything={false}></ErrorSummary>
                <TextField
                    label={intl.get('editableNode.placeholder.nodeName')}
                    size="small"
                    type="text"
                    id="actionName"
                    name="actionName"
                    value={actionName}
                    onChange={handleActionNameChange}
                    disabled={disabled}
                    autoFocus
                    className="w-100"
                />
                <TextField
                    label={intl.get('editableNode.placeholder.labelKey')}
                    size="small"
                    type="text"
                    id="name"
                    name="name"
                    value={labelKey}
                    onChange={handleLabelKeyChange}
                    disabled={disabled}
                    autoFocus
                    className="w-100"
                />
                <Box>
                    <InputLabel>{intl.get('editableNode.placeholder.commentAllowed')}</InputLabel>
                    <Switch
                    classes={{
                        thumb: 'border border-gray'
                    }}
                    checked={allowComments} onChange={() => setAllowComments(!allowComments)} />
                </Box>
                <Box display={"flex"} flexDirection={'row'} gap={2}>
                    <Box>
                        <InputLabel>{intl.get('editableNode.placeholder.icon')}</InputLabel>
                        <IconPicker
                            pickButtonStyle={{
                                color: color,
                            }}
                            value={selectedIcon}
                            onChange={(v) => setSelectedIcon(v)}
                        />
                    </Box>
                    <Box>
                        <InputLabel>{intl.get('editableNode.placeholder.color')}</InputLabel>
                        <ColorPicker color={color} setColor={setColor} />
                    </Box>
                </Box>
                <ChipSelector
                    label="Scope"
                    value={[{id:parseInt(scope), scope: ""}]}
                    onChange={handleViewScopeChange}
                    scopes={scopes}
                    multiple={false}
                />
                <Box display={"flex"} gap={2} sx={{width: 'fit-content', alignSelf: 'end'}}>
                    {editMode &&
                        <Button startIcon={<FaTrash/>} variant="contained" color="error" onClick={() => {
                            handleDelete((selectedEdge as FloatingEdgeType)?.id)
                        }} sx={{width: 'fit-content', alignSelf: 'end'}} >
                            {intl.get('delete.button')}
                        </Button>
                    }
                    <Button startIcon={<FaSave/>} variant="contained" color="primary" onClick={() => handleSave()} sx={{width: 'fit-content', alignSelf: 'end'}}>
                        {intl.get('save.modal.save.button')}
                    </Button>
                </Box>
            </Stack>
        </Drawer>
    )
}

export default FloatingEdgeDrawer

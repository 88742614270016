import React from 'react';
import WorkflowRow from './WorkflowRow';
import { BiSortZA, BiSortAZ } from 'react-icons/bi';
import intl from 'react-intl-universal';

const WorkflowList = ({ searchResult, input, setInput }) => {

    const handleSortClick = (sort) => {
        setInput({ ...input, orderValue: (input.sortValue == sort ? (input.orderValue == 'desc' ? 'asc' : 'desc') : 'asc'), sortValue: sort, currentPage: 1 });
    }

    return (
        <div className="table-responsive my-2">
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th role='button' onClick={() => { handleSortClick('ModuleName') }} scope="col">{intl.get('workflowList.table.thead.modulename')} {input.sortValue == 'ModuleName' ? (input.orderValue == 'desc' ? <BiSortZA></BiSortZA> : <BiSortAZ></BiSortAZ>) : ''}</th>
                        <th role='button' onClick={() => { handleSortClick('Name') }} scope="col">{intl.get('workflowList.table.thead.name')} {input.sortValue == 'Name' ? (input.orderValue == 'desc' ? <BiSortZA></BiSortZA> : <BiSortAZ></BiSortAZ>) : ''}</th>
                    </tr>
                </thead>
                {
                    searchResult.workflows === null && input.queryTerm !== null ? 
                    <tbody className='aling-center mb-0'>
                        <tr>
                            <td colSpan={6} className=' mb-0'>{intl.get('workflowList.notFound')}</td>
                        </tr>
                    </tbody> :
                    <tbody>
                        {
                            searchResult.workflows && searchResult.workflows.map((c,i) => (
                                <WorkflowRow 
                                key={c.id} 
                                id={c.id} 
                                name={c.name} 
                                moduleName={c.module.name}
                                className={i%2>0?'':'odd'}
                                />
                            ))
                        }
                    </tbody>
                }
            </table>
        </div>
    )
}

export default WorkflowList;
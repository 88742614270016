import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import Chip from '@mui/material/Chip';

function ScopeMultiSelect({ handleScopeChange, selectedScopes, scopes, disabled }) {
  
  const popperOptions = {
    modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          altBoundary: true,
          fallbackPlacements: ['bottom'],
        },
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: true,
          tether: false,
        },
      },
    ],
  };

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      id="scopes"
      options={scopes ? scopes : []}
      value={selectedScopes}
      onChange={(event, newValue) => handleScopeChange(newValue)}
      getOptionLabel={(scope) => scope?.scope}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Scopes"
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={option.scope}
            color="primary"  
            variant="outlined" 
            {...getTagProps({ index })}
          />
        ))
      }
      PopperComponent={(props) => (
        <Popper {...props} placement="bottom-start" {...popperOptions} />
      )}
      style={{ maxHeight: '59vh', overflow: 'auto', paddingTop: '2%' }}
    />
  );
}

export default ScopeMultiSelect;


import React, { useEffect } from "react";
import { BrowserRouter } from "react-router";
import ApiProvider from './api/ApiProvider';
import { PageLayout } from './components/PageLayout';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'scss/global/ConfirmAlert.scss';
import { UserEntityProvider } from 'common/UserEntityProvider'
import AuthProvider from './components/authentication/context/authContext'
import { ToastMessageProvider } from "./components/ToastMessages/ToastMessageProvider";
import { ToastMessageQueue } from "./components/ToastMessages/ToastMessageQueue";
import BerpRoutes from './components/common/routes/BerpRoutes';

export default function App() {
  useEffect(() => {
    const splashScreen = document.querySelector('.splash-screen');
    if(!splashScreen) return;
    splashScreen.classList.add('fadeOut');
    setTimeout(() => {
      splashScreen.remove();
  }, 2000);

  }, []);

  return (
    <BrowserRouter>
    <ToastMessageProvider>
    <AuthProvider>
    <ApiProvider>
    <UserEntityProvider>
    <PageLayout>
        <ToastMessageQueue autoDelete={true} autoDeleteTime={3000}></ToastMessageQueue>
        <BerpRoutes />
    </PageLayout>
    </UserEntityProvider>
    </ApiProvider>
    </AuthProvider>
    </ToastMessageProvider> 
    </BrowserRouter>
  );
}
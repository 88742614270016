import React from "react";
import { Autocomplete, Chip, TextField } from "@mui/material"
import { useState } from "react"
import { Scope } from "axiosApi/models/scope";
type AutocompleteOption = { scope: string, id: number }

type ChipSelectorProps = {
    label: string;
    value: any; //Scope[] || Scope
    onChange: any;
    scopes: Scope[];
    multiple?: boolean;
}

const ChipSelector: React.FC<ChipSelectorProps> = ({ label, onChange, value, scopes, multiple = true }) => {
    //const [selectedScopes, setSelectedScopes] = useState<any>(value)
    
    return (
        <Autocomplete
            multiple={multiple}
            options={scopes}
            size="small"
            value={value}
            isOptionEqualToValue={(option, value) =>{
                if(value instanceof Array) return option?.id?.toString() === value[0]?.id?.toString()
                else return option?.id?.toString() === value?.id?.toString()
            }
            }
            onChange={(event, newValue) => {
                console.log(`Autocomplete onChange ${newValue}`, newValue)
               
                if (!!onChange) onChange(newValue)
            }}
            getOptionLabel={(scope) => { 
                if(scope instanceof Array) return scopes.find(x=>x.id == scope[0]?.id)?.scope|| ''
                else return scopes.find(x=>x.id == scope?.id)?.scope|| ''}}
            renderGroup={(params) => [
                <div key={params.key}>
                    <strong>{params.group}</strong>
                </div>
            ]}
            renderTags={(value, getTagProps) =>{
                if(multiple){
                    return value?.map((option, index) => { console.log(option,index); return(
                        <Chip
                            key={index}
                            label={ scopes.find(x=>x.id=== option.id)?.scope}
                            color="primary"
                            variant="outlined"
                            {...getTagProps({ index })}
                        />
                    )})
               }else{
                    return(
                        <Chip
                            key={0}
                            label={ scopes.find(x=>x.id=== value[0])?.scope }
                            color="primary"
                            variant="outlined"
                            {...getTagProps({ index: 0 })}
                        />
                    )
                }
            }}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label={label} placeholder="Select scopes" />
            )}
        />
    )
}

export default ChipSelector;
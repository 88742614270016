import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { MessageProps } from './ToastMessageQueue';
export type ToastMessageProviderProps = {
    messageQueue?: Array<MessageProps>,
    success?: Function,
    error?: Function,
    info?: Function,
    warning?: Function,
    DeleteToastMessage?: Function

}

export const ToastMessageQueueContext = createContext<ToastMessageProviderProps>({});

type GroupedByTypeAndMessage = {
    [key: string]: MessageProps
}

export const ToastMessageProvider = ({ children }) => {
    const [messageQueue, setMessageQueue] = useState<Array<MessageProps>>([]);

    /*
    const messageQueueGroupedByTypeAndMessage = useMemo<Array<MessageProps>>(() => {
        const groupedByTypeAndMessage: GroupedByTypeAndMessage = messageQueue.reduce((acc:GroupedByTypeAndMessage, message: MessageProps) => {
            if (!acc[`${message.type}-${message.body}`]) {
                acc[`${message.type}-${message.body}`] = { ...message, count: 1 }
                return acc;
            }
            acc[`${message.type}-${message.body}`] = {
                ...message,
                count: acc[`${message.type}-${message.body}`].count + 1
            }
            return acc;
        }, {});

        return Object.values(groupedByTypeAndMessage);

    }, [messageQueue]);
    */

    const checkIfTypeAndMessageExists = useCallback((message: MessageProps) => {
        return messageQueue.some(e => e.type === message.type && e.body === message.body);
    }, [messageQueue]);

    const addCountToMessage = useCallback((message: MessageProps) => {
        const index = messageQueue.findIndex(e => e.type === message.type && e.body === message.body);
        messageQueue[index].count = messageQueue[index].count + 1;
        setMessageQueue([...messageQueue]);
    }, [messageQueue]);

    const AddToastMessage = (message: MessageProps) => {
        setMessageQueue(prevMessages => {
            const index = prevMessages.findIndex(e => e.type === message.type && e.body === message.body && e.header === message.header);
            if (index !== -1) {
                const updatedMessages = [...prevMessages];
                updatedMessages[index].count = updatedMessages[index].count + 1;
                return updatedMessages;
            }
            const idMessage = prevMessages.length > 0 && prevMessages.at(prevMessages.length - 1).id ? prevMessages.at(prevMessages.length - 1).id + 1 : 0;
            return [...prevMessages, { ...message, count: 1, id: idMessage }];
        });
    }
    const DeleteToastMessage = id => {
        const index = messageQueue.findIndex(e => e.id === id);
        messageQueue.splice(index, 1);
        setMessageQueue([...messageQueue]);

    };
    const success = (message: MessageProps) => {
        AddToastMessage({ ...message, type: 'success' });
    }
    const error = (message: MessageProps) => {
        AddToastMessage({ ...message, type: 'error' });
    }
    const info = (message: MessageProps) => {
        AddToastMessage({ ...message, type: 'info' });
    }
    const warning = (message: MessageProps) => {
        AddToastMessage({ ...message, type: 'warning' });
    }
    return <ToastMessageQueueContext.Provider value={{
        messageQueue: messageQueue, error: error, success: success, warning: warning, info: info, DeleteToastMessage: DeleteToastMessage
    }} >
        {children}
    </ToastMessageQueueContext.Provider >
}

// export const ShowToastMessage = (message) => { useContext(ToastMessageQueueContext).ShowToastMessage(message) };

export function useToastMessageQueue() {
    return useContext(ToastMessageQueueContext);
}

import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'react-intl-universal';

type InvoicesStatusSelectProps = {
  invoiceStatus: any,
  selectedStatus: any,
  handleStatusChange: any,
  isClearable: boolean
};

const InvoicesStatusSelect: FC<InvoicesStatusSelectProps> = ({ invoiceStatus, selectedStatus, handleStatusChange, isClearable }) => {
 
  return (
    <>
      <Select
        id='invoiceStatus'
        name='invoiceStatus'
        getOptionLabel={e => e.name}
        getOptionValue={e => e.id}
        placeholder="Status"
        value={(invoiceStatus != null && selectedStatus != null) ? (invoiceStatus.find((status) => status.id === selectedStatus)) : ''}
        onChange={handleStatusChange}
        isLoading={invoiceStatus == null}
        options={invoiceStatus}
        isClearable={isClearable}
      />
    </>
  );
}

export default InvoicesStatusSelect;
import React from 'react';
import { useNavigate } from 'react-router';

const UserRow = ({ id, email, className }) => {

    const navigate = useNavigate();

    const handleClickRow = () => {
        navigate('/user/detail/' + id);
    };

    return (
        <>
            {
                email ?
                <tr onClick={handleClickRow} className={className}>
                    <td>{email}</td>
                </tr> :
                null
            }
        </>
    )   
}

export default UserRow;
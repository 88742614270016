import React, { memo } from 'react';
import { Handle, Node, NodeProps, Position, useConnection } from '@xyflow/react';
import { Box, Chip, Paper, Typography, useTheme } from '@mui/material';
import '../../../scss/Workflow/EditableNode.scss';
import { MdNotStarted, MdStopCircle } from 'react-icons/md';
import intl from 'react-intl-universal'

export type EditableNodeType = Node<{
    internalId: string,
    name: string,
    labelKey: string,
    initial: boolean,
    final: boolean,
    color: string,
    transitionEditMode: boolean,
    extraAttributes: any,
    editScopes: string[],
    viewScopes: string[],
    deleteScopes: string[],
}, 'editableNode'>;

export default memo(({ data }: NodeProps<EditableNodeType>) => {
    const connection = useConnection();
    const isTarget = connection.inProgress && connection.fromNode.id !== data.internalId;
    const isConnecting = !isTarget && connection.toNode?.id === data.internalId;

    const theme = useTheme();
    return (
        <div className="custonNode">
            {data?.extraAttributes?.virtualInitialNode || data?.extraAttributes?.virtualFinalNode ? (
                <>
                    {data?.extraAttributes?.virtualInitialNode && <MdNotStarted size={30} className='initialVirtualNode' />}
                    {data?.extraAttributes?.virtualFinalNode && <MdStopCircle size={30} className='finalVirtualNode' />}
                </>
            ) : 
            <Box className="paperContainer">
                <Chip
                    className={`border px-4 py-2 ${isTarget?'target':'targeting'} ${isConnecting?'connecting':'no-connecting'} ${connection.inProgress?'connectionInProgess':''}`}
                    label={<Typography className='statusLabel'>{(data?.labelKey && intl.get(data?.labelKey))|| data?.name}</Typography>}
                    sx={{backgroundColor: data.color?data.color:'#fff', color: theme.palette.getContrastText(data.color?data.color:'#fff')}}
                />
            </Box>
            }
                        
            {(!connection.inProgress) && data.transitionEditMode ? (
                <Handle className="customHandle" position={Position.Right} type="source"/>
            ):
                <Handle className="d-none" position={Position.Right} type="source"/>
            }
            {/* We want to disable the target handle, if the connection was started from this node */}
            {(!connection.inProgress || isTarget) && (
                <Handle className="customHandle" position={Position.Left} type="target" isConnectableStart={false} />
            )}
        </div>
    );
});
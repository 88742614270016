import React from 'react';
import { HexColorPicker } from 'react-colorful'
import { Box, Button, Popover, useTheme } from '@mui/material';

type ColorPickerProps = {
    color: string
    setColor: (color: string) => void
    className?: string
}

export default function ColorPicker({ color, setColor, className }: ColorPickerProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const theme = useTheme();

  return (
    <>
      <Button size='small' variant="outlined" className={`p-2 ${className}`} color='inherit' onClick={handleClick}>
        <Box style={{ width: 22, height: 22, backgroundColor: color, borderRadius: "100%", border: `1px solid ${theme.palette.getContrastText(color)}`}}></Box>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className='p-4'
      >
        <Box className="p-4">
            <HexColorPicker color={color} onChange={setColor} />
        </Box>
      </Popover>
    </>
  );
}




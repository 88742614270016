import React from 'react';
import { Link } from 'react-router';
import WorkflowSearchBar from './WorkflowSearchBar';
import '../../scss/Workflow/WorkflowNavbar.scss';
import { HiOutlinePlus } from 'react-icons/hi';
import { Tooltip as ReactTooltip } from "react-tooltip";
import intl from 'react-intl-universal';

const WorkflowNavbar = ({ onSearch }) => {
    
    return (
        <nav className='navbar container-fluid'>
            <WorkflowSearchBar onSearch={onSearch}/>
            <Link to= '/workflow/new'><button className='btn btn-primary p-1 plusButton' type='button' id='newWorkflow' data-tooltip-id='tooltipButton'><HiOutlinePlus className='plusIcon'/></button></Link>
            <ReactTooltip 
                id='tooltipButton'
                place='left'
                content={intl.get('navbar.newWorkflow.button')}
            />
        </nav>
    )
}

export default WorkflowNavbar;
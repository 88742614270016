/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Boilerplate API
 * You need to change all the object names to your project ones
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AddressSearchResponseResponse } from '../models/address-search-response-response';
import { CorporateEntityResponse } from '../models/corporate-entity-response';
import { CorporateEntitySearchResponseResponse } from '../models/corporate-entity-search-response-response';
import { CreateCorporateEntityCommand } from '../models/create-corporate-entity-command';
import { StatusCodeResult } from '../models';
import { StringResponse } from '../models';
import { UpdateCorporateEntityCommand } from '../models/update-corporate-entity-command';
/**
 * CorporateEntityApi - axios parameter creator
 * @export
 */
export const CorporateEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionCorporateEntityAllGet: async (version: string, pageNum?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionCorporateEntityAllGet.');
            }
            const localVarPath = `/api/v{version}/CorporateEntity/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionCorporateEntityIdAddressesGet: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionCorporateEntityIdAddressesGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionCorporateEntityIdAddressesGet.');
            }
            const localVarPath = `/api/v{version}/CorporateEntity/{id}/Addresses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionCorporateEntityIdDelete: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionCorporateEntityIdDelete.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionCorporateEntityIdDelete.');
            }
            const localVarPath = `/api/v{version}/CorporateEntity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionCorporateEntityIdGet: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionCorporateEntityIdGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionCorporateEntityIdGet.');
            }
            const localVarPath = `/api/v{version}/CorporateEntity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateCorporateEntityCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionCorporateEntityPost: async (version: string, body?: CreateCorporateEntityCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionCorporateEntityPost.');
            }
            const localVarPath = `/api/v{version}/CorporateEntity`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {UpdateCorporateEntityCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionCorporateEntityPut: async (version: string, body?: UpdateCorporateEntityCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionCorporateEntityPut.');
            }
            const localVarPath = `/api/v{version}/CorporateEntity`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionCorporateEntitySearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionCorporateEntitySearchGet.');
            }
            const localVarPath = `/api/v{version}/CorporateEntity/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorporateEntityApi - functional programming interface
 * @export
 */
export const CorporateEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CorporateEntitySearchResponseResponse>>> {
            const localVarAxiosArgs = await CorporateEntityApiAxiosParamCreator(configuration).apiVversionCorporateEntityAllGet(version, pageNum, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityIdAddressesGet(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AddressSearchResponseResponse>>> {
            const localVarAxiosArgs = await CorporateEntityApiAxiosParamCreator(configuration).apiVversionCorporateEntityIdAddressesGet(id, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityIdDelete(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<StatusCodeResult>>> {
            const localVarAxiosArgs = await CorporateEntityApiAxiosParamCreator(configuration).apiVversionCorporateEntityIdDelete(id, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CorporateEntityResponse>>> {
            const localVarAxiosArgs = await CorporateEntityApiAxiosParamCreator(configuration).apiVversionCorporateEntityIdGet(id, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateCorporateEntityCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityPost(version: string, body?: CreateCorporateEntityCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CorporateEntityResponse>>> {
            const localVarAxiosArgs = await CorporateEntityApiAxiosParamCreator(configuration).apiVversionCorporateEntityPost(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {UpdateCorporateEntityCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityPut(version: string, body?: UpdateCorporateEntityCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CorporateEntityResponse>>> {
            const localVarAxiosArgs = await CorporateEntityApiAxiosParamCreator(configuration).apiVversionCorporateEntityPut(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntitySearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CorporateEntitySearchResponseResponse>>> {
            const localVarAxiosArgs = await CorporateEntityApiAxiosParamCreator(configuration).apiVversionCorporateEntitySearchGet(version, term, pageNum, pageSize, sort, order, f, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CorporateEntityApi - factory interface
 * @export
 */
export const CorporateEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<CorporateEntitySearchResponseResponse>> {
            return CorporateEntityApiFp(configuration).apiVversionCorporateEntityAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityIdAddressesGet(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AddressSearchResponseResponse>> {
            return CorporateEntityApiFp(configuration).apiVversionCorporateEntityIdAddressesGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityIdDelete(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<StatusCodeResult>> {
            return CorporateEntityApiFp(configuration).apiVversionCorporateEntityIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<CorporateEntityResponse>> {
            return CorporateEntityApiFp(configuration).apiVversionCorporateEntityIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateCorporateEntityCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityPost(version: string, body?: CreateCorporateEntityCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<CorporateEntityResponse>> {
            return CorporateEntityApiFp(configuration).apiVversionCorporateEntityPost(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {UpdateCorporateEntityCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntityPut(version: string, body?: UpdateCorporateEntityCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<CorporateEntityResponse>> {
            return CorporateEntityApiFp(configuration).apiVversionCorporateEntityPut(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionCorporateEntitySearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<CorporateEntitySearchResponseResponse>> {
            return CorporateEntityApiFp(configuration).apiVversionCorporateEntitySearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CorporateEntityApi - object-oriented interface
 * @export
 * @class CorporateEntityApi
 * @extends {BaseAPI}
 */
export class CorporateEntityApi extends BaseAPI {
    /**
     * 
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateEntityApi
     */
    public async apiVversionCorporateEntityAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<CorporateEntitySearchResponseResponse>> {
        return CorporateEntityApiFp(this.configuration).apiVversionCorporateEntityAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateEntityApi
     */
    public async apiVversionCorporateEntityIdAddressesGet(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AddressSearchResponseResponse>> {
        return CorporateEntityApiFp(this.configuration).apiVversionCorporateEntityIdAddressesGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateEntityApi
     */
    public async apiVversionCorporateEntityIdDelete(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<StatusCodeResult>> {
        return CorporateEntityApiFp(this.configuration).apiVversionCorporateEntityIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateEntityApi
     */
    public async apiVversionCorporateEntityIdGet(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<CorporateEntityResponse>> {
        return CorporateEntityApiFp(this.configuration).apiVversionCorporateEntityIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} version 
     * @param {CreateCorporateEntityCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateEntityApi
     */
    public async apiVversionCorporateEntityPost(version: string, body?: CreateCorporateEntityCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<CorporateEntityResponse>> {
        return CorporateEntityApiFp(this.configuration).apiVversionCorporateEntityPost(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} version 
     * @param {UpdateCorporateEntityCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateEntityApi
     */
    public async apiVversionCorporateEntityPut(version: string, body?: UpdateCorporateEntityCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<CorporateEntityResponse>> {
        return CorporateEntityApiFp(this.configuration).apiVversionCorporateEntityPut(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateEntityApi
     */
    public async apiVversionCorporateEntitySearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<CorporateEntitySearchResponseResponse>> {
        return CorporateEntityApiFp(this.configuration).apiVversionCorporateEntitySearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(this.axios, this.basePath));
    }
}

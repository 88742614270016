import React, { useEffect, FC, useContext } from 'react';
import { useSearchParams } from 'react-router';
import { useNavigate } from "react-router";
import { AuthContext, AuthStatus } from 'components/authentication/context/authContext';
import Spinner from './Spinner';
export const Verify: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const hashCode = searchParams.get("code");
        authContext.verifyCode(hashCode);
    }, [])

    useEffect(() => {
        if(authContext.authStatus === AuthStatus.SignedIn){
            navigate("/");
        }
    }, [authContext.authStatus])

    
    return (<Spinner/>);
};
import React from 'react';
import { useNavigate } from 'react-router';
import intl from 'react-intl-universal';
import { HiEye } from 'react-icons/hi';

const CorporateEntitiesRow = ({ className, id, name, legalName, countryLabelKey, taxID }) => {
    const navigate = useNavigate();
    const handleCorporateEntitiesDetail = () => {
        navigate(`/corporateEntities/detail/${id}`);
    };
    return (
        <tr className={className}>
            <td>{name}</td>
            <td>{legalName}</td>
            <td>{intl.get(`${countryLabelKey}`)}</td>
            <td>{taxID}</td>
            <td className='text-center'>
                <a className='td-icon mx-1' title="Detail">
                    <HiEye onClick={() => handleCorporateEntitiesDetail()}/>
                </a>
            </td>
        </tr>
    )
}

export default CorporateEntitiesRow;

import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'react-intl-universal';
import { ListOptionDTO } from 'axiosApi/models';
import { Console } from 'console';

type PersonaTypeSelectProps = {
  handlePersonaTypeChange: any,
  selectedPersonaType: number,
  PersonaTypes: any,
};

const PersonaTypeSelect: FC<PersonaTypeSelectProps> = (props) => {
  
  const { PersonaTypes, selectedPersonaType, handlePersonaTypeChange } = props
  return (
    <Select
      id="personaTypes"
      name="personaTypes"
      getOptionLabel={e =>  intl.get(e.label)}
      getOptionValue={e => e.id}
      placeholder={intl.get('personaForm.placeholder.personaType')}
      onChange={handlePersonaTypeChange}
      isLoading={PersonaTypes == null}
      options={PersonaTypes}
      value={
            (PersonaTypes != null && selectedPersonaType != null) 
            && 
            (PersonaTypes.find((personaTypes) => personaTypes.id === selectedPersonaType)) 
            }
    />
  );
}

export default PersonaTypeSelect;